.input_component {
    position: relative;
}

.input_component span {
    display: block;
    font-size: 14px;
    line-height: 22px;
    text-align: start;
    color: #2E3A59;
    margin-bottom: 4px;
}

.input_search > div {
    position: relative;
}

.input_search .searchImage {
    background-repeat: no-repeat;
    background-size: cover;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 13px;
    top: 9px;
}

.dropdown_component_wrapper {
    position: relative;
    cursor: pointer;
}

.dropdown_component_label {
    position: relative;
}

.dropdown_component_wrapper input {
    cursor: pointer;
}

.dropdown_component_wrapper .dropdown_image {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.dropdown_component_wrapper .dropdown_search {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.dropdown_component_wrapper .dropdown_component_opened {
    position: absolute;
    left: 0;
    transform: translateY(8px);
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #E2E4E9;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
    border-radius: 3px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 999;
}

.dropdown_component_wrapper .dropdown_component_opened > span,
.dropdown_component_wrapper .dropdown_component_opened label {
    width: 100%;
    padding: 8px 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #4E5D78;
    margin: 0;
}

label {
    margin-bottom: unset;
}

.dropdown_component_wrapper .dropdown_component_opened span:hover,
.dropdown_component_wrapper .dropdown_component_opened div:hover {
    background: #EBEBF0;
}

/*.input_search:hover .searchImage{*/
/*    background-image: url("../../assets/search_active.svg");*/
/*}*/

/*.search_image_active {*/
/*    background-image: url ("../../assets/search_active.svg") !important;*/
/*}*/

.input_component .custom_input,
.input_component .textarea {
    padding: 12px 15px;
    width: 100%;
    background: #F9F9F9;;
    border: 1px solid #F9F9F9;
    box-sizing: border-box;
    border-radius: 0;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    resize: none;
    transition: .3s all;
}

.input_component .textarea {
    padding: 16px;
}

.input_component label {
    position: relative;
}

.input_component .custom_input::placeholder,
.input_component .textarea::placeholder {
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #8E90A6;
}

.input_search .custom_input {
    padding: 6px 11px 6px 42px !important;
}

.input_component .custom_input:focus,
.input_component .textarea:focus {
    border: 1px solid #000;
    cursor: text;
}

.input_component .custom_input:disabled,
.input_component .textarea:disabled {
    background: #F2F2F5;
    border: 1px solid #C7C8D9;
    color: #8E90A6;
    border-radius: 4px;
    cursor: auto;
}

.show_pass_icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 15;
}

.social_icon {
    position: absolute;
    right: 12px;
    top: 0;
}

.show_pass_icon span {
    margin: 0 .25rem 0 0;
}

.invitation_user {
    position: absolute;
    right: 10px;
    top: -1px;
}

.invitation_user span {
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #41A0FC;
    margin: 0;
}

.error,
.error:focus,
.error:hover {
    border: 1px solid #EB4250 !important;
}

.unique_goods_order_popup_content_label input {
    padding: 0 1rem 1rem 0 !important;
    background: transparent !important;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    color: #333333 !important;
    border: none !important;
    border-bottom: 1px solid #333333 !important;
}

.unique_goods_order_popup_content_error input, .unique_goods_order_popup_content_error input:focus,
.unique_goods_order_popup_content_error input:hover {
    border: none !important;
    border-bottom: 1px solid #EB4250 !important;
    padding: 0 1rem 1rem 0 !important;
    background: transparent !important;
}