.wrapper_order_preview {
    background: #333;
    padding: 80px 100px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 60px;
}

.wrapper_cart_items {
    padding: 20px 0;
}

.wrapper_order_preview .wrapper_delivery_description .wrapper_security_pay {
    display: grid;
    padding: 28px 0;
    grid-template-columns: max-content 1fr;
    grid-gap: 12px;
}

.wrapper_order_preview .wrapper_delivery_description .wrapper_security_pay span {
    font-size: 16px;
    line-height: 22px;
    color: #fff
}

.wrapper_order_preview .wrapper_delivery_description .wrapper_security_pay_icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
}

.wrapper_order_preview .wrapper_delivery_description .wrapper_security_pay_icons img {
    justify-self: center;
    align-self: center;
}

.wrapper_order_preview .wrapper_delivery_description .wrapper_return {
    margin-top: 30px;
}

.wrapper_order_preview .wrapper_delivery_description .wrapper_return .wrapper_return_desc {
    font-size: 14px;
    line-height: 19px;
    color: #D3D3D3;
}

.wrapper_order_preview .wrapper_delivery_description .wrapper_delivery_link {
    margin-top: 24px;
}

.wrapper_order_preview .wrapper_delivery_description .wrapper_delivery_link a {
    font-size: 14px;
    line-height: 19px;
    color: #C69C6D;
    text-decoration: underline;
}

.wrapper_cart_items .wrapper_title {
    margin: 40px 0;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #000
}

.wrapper_cart_items .titles_preview {
    display: grid;
    grid-template-columns: 400px repeat(3, 1fr) 140px;
    grid-gap: 12px;
}

.wrapper_cart_items .titles_preview .title {
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    justify-self: center;
    color: #333
}

.wrapper_cart_items .titles_preview .title:first-child {
    justify-self: start;
}

.wrapper_cart_items .wrapper_every_items {
    border: 1px solid #D3D3D3;
    z-index: 1;
    position: relative;
    box-sizing: border-box;
}

.wrapper_cart_items .wrapper_every_items .wrapper_preview_item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_image {
    width: 200px;
    background: rgba(196, 196, 196, 0.1);
    height: 100%;
}

.wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_info .wrapper_item_title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333
}

.wrapper_cart_items .wrapper_every_items .wrapper_item_quantity {
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    justify-self: center;
    align-self: center;
}

.wrapper_cart_items .wrapper_every_items .wrapper_item_quantity .item_quantity {
    width: 90px;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
}

.wrapper_cart_items .wrapper_every_items .wrapper_item_quantity .item_quantity span {
    font-size: 16px;
    line-height: 19px;
    align-self: center;
    color: #333
}

.wrapper_cart_items .wrapper_every_items .wrapper_item_quantity .item_quantity .add_quantity,
.wrapper_cart_items .wrapper_every_items .wrapper_item_quantity .item_quantity .subtract_quantity {
    cursor: pointer;
    justify-content: center;
}

.cart_quantity_buttons {
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    margin: 0;
}

.cart_quantity_buttons:disabled, .cart_quantity_buttons:disabled span {
    cursor: auto !important;
    color: #D3D3D3 !important;
}

.wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_info .wrapper_item_desc {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #828282;
}

.wrapper_cart_items .wrapper_every_items .wrapper_item_price {
    font-size: 18px;
    line-height: 25px;
    justify-content: center;
    color: #333
}

.wrapper_cart_items .wrapper_every_items .wrapper_item_price .discount_price {
    text-decoration-line: line-through;
    color: #D3D3D3;
}

.wrapper_cart_items .wrapper_every_items .wrapper_delete_item_btn {
    font-size: 12px;
    line-height: 16px;
    color: #828282;
    cursor: pointer;
    align-self: center;
    justify-self: center;
    text-decoration: underline;
}

.wrapper_cart_items .wrapper_every_items .align_block {
    align-content: center;
    display: grid;
}

.wrapper_cart_items .wrapper_every_items .wrapper_item_options {
    border: 1px solid #C69C6D;
    box-sizing: border-box;
    justify-self: center;
    align-self: center;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #C69C6D;
    display: grid;
    grid-auto-flow: column;
    cursor: pointer;
}

.wrapper_cart_items .wrapper_every_items .wrapper_item_options img {
    margin: 0 0 0 6px;
}

.wrapper_main_info_product {
    margin: 32px 0;
}

.wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_quantity,
.wrapper_delete_item_btn_mobili {
    display: none;
}

.wrapper_main_info_product .options_wrapper {
    border: 1px solid #D3D3D3;
    border-top: none;
    box-sizing: border-box;
    opacity: 0;
    height: 100%;
    transition: .4s;
    padding: 0;
}

.wrapper_main_info_product .active_options_wrapper {
    padding: 40px 50px;
    opacity: 1;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px;
    overflow-y: scroll;
}

.wrapper_main_info_product .un_active_options_wrapper {
    height: 0;
    overflow-y: hidden;
}

.wrapper_main_info_product .active_options_wrapper .wrapper_every_filters {
    display: grid;
    grid-gap: 22px;
    height: max-content;
}


.wrapper_order_sum {
    display: grid;
    grid-gap: 12px
}

.wrapper_order_sum .wrapper_promo_code {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    margin-top: 26px;
}

.order_preview_modal_wrapper {
    width: 100%;
    height: 100vh;
    background: rgba(51, 51, 51, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
}

.order_preview_modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    width: 640px;
    padding: 1.5rem;
}

.wrapper_order_sum .wrapper_promo_code .wrapper_submit_promo {
    border: 1px solid #fff;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    display: grid;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    padding: 16px;
    box-sizing: border-box;
}

.wrapper_order_sum .title_wrapper {
    font-size: 36px;
    margin-bottom: 12px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #fff
}

.wrapper_every_order_total {
    border-bottom: 1px solid #4f4f4f;
    padding: 12px 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.wrapper_every_order_total span {

}

.wrapper_every_order_total > div {
    justify-self: end;
}

.wrapper_every_order_total > div .sale_price {
    font-size: 18px;
    padding-right: 12px;
    line-height: 25px;
    text-decoration-line: line-through;
    color: #BDBDBD;
}

.wrapper_every_order_total span,
.wrapper_every_order_total > div span:last-child {
    font-size: 16px;
    line-height: 22px;
    color: #fff
}

.wrapper_order_sum .wrapper_promo_code button {
    margin-left: 20px;
}

.wrapper_order_sum .wrapper_promo_code .wrapper_promo_error {
    color: #EB4250;
    font-size: 14px;
    line-height: 18px;
    align-self: center;
    padding-left: 8px;
}

.wrapper_order_sum .wrapper_price .procent {
    padding-right: 12px;
}

@media (max-width: 1366px) {
    .wrapper_cart_items .titles_preview {
        display: grid;
        grid-template-columns: 250px repeat(3, 1fr) 140px;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_image {
        width: 150px
    }

    .wrapper_order_sum .wrapper_promo_code {
        display: grid;
        grid-template-columns: 2fr max-content;
        margin-top: 26px;
    }

    .wrapper_order_sum .wrapper_promo_code button {
        grid-column: 1/-1;
        margin: 10px 0 0 0;
    }

    .wrapper_order_preview {
        grid-template-columns: 1fr 1fr;
    }
}

.wrapper_cart_items .wrapper_every_items .wrapper_info .wrapper_item_price {
    display: none;
}

@media (max-width: 1150px) {
    .wrapper_cart_items .titles_preview {
        display: grid;
        grid-template-columns: 400px repeat(3, 1fr) 140px;
    }
}

@media (max-width: 900px) {
    .wrapper_cart_items .titles_preview {
        display: grid;
        grid-template-columns: 300px repeat(3, 1fr) 140px;
    }

    .wrapper_order_preview {
        padding: 80px 52px;
        grid-gap: 24px;
    }
}

@media (max-width: 768px) {
    .wrapper_cart_items .wrapper_every_items .wrapper_item_quantity,
    .wrapper_cart_items .delete_item,
    .wrapper_cart_items .wrapper_every_items .wrapper_delete_item_btn {
        display: none;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_quantity {
        display: block;
        justify-self: start;
        margin-top: 12px;
    }

    .wrapper_delete_item_btn_mobili {
        position: absolute;
        top: 6px;
        right: 6px;
        display: block;
        cursor: pointer;
        padding: 12px;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_image {
        height: 200px;
    }

    .wrapper_cart_items .titles_preview {
        grid-template-columns: 150px 130px 1fr 140px;
    }

    .wrapper_cart_items .wrapper_every_items {
        grid-template-columns: 280px 1fr 140px;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_item_price {
        justify-self: start;
        padding-left: 12px;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_info .wrapper_item_title {
        font-size: 18px;
    }

    .wrapper_cart_items .titles_preview .title {
        justify-self: start;
    }

    .wrapper_cart_items .titles_preview .title:last-child {
        justify-self: center;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item {
        grid-gap: 12px;
    }

    .hidden_item {
        visibility: hidden;
    }

    .wrapper_order_preview {
        padding: 68px 20px;
        grid-gap: 24px;
        grid-template-columns: 1fr;
    }

    .wrapper_order_preview .wrapper_order_sum {
        grid-row: 1;
    }

    .order_preview_modal {
        width: 100%;
        padding: 1.5rem .5rem;
    }
}

@media (max-width: 560px) {
    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_quantity,
    .wrapper_cart_items .wrapper_every_items .wrapper_item_price,
    .wrapper_cart_items .titles_preview {
        display: none
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_item_quantity,
    .wrapper_cart_items .wrapper_every_items .wrapper_info .wrapper_item_options,
    .wrapper_cart_items .wrapper_every_items .wrapper_info .wrapper_item_price {
        display: block;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_image {
        width: 170px;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item {
        grid-template-columns: 170px 1fr;
    }

    .wrapper_cart_items .wrapper_main_info_product .titles_preview {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item {
        grid-column: 1/-1;
    }

    .wrapper_main_info_product {
        margin: 12px 0;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_info .wrapper_item_price {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 12px;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_item_options,
    .wrapper_cart_items .wrapper_every_items .wrapper_item_quantity {
        grid-row: 2;
        margin-bottom: 12px;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_item_price {
        padding-left: 0;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_item_quantity {
        grid-column: 1;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_item_options {
        grid-column: 2;
    }

}

@media (max-width: 450px) {
    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_image {
        width: 140px;
    }

    .wrapper_cart_items .wrapper_every_items .wrapper_preview_item {
        grid-template-columns: 140px 1fr;
    }

}

@media (max-width: 350px) {
    /*.wrapper_order_sum .wrapper_promo_code {*/
    /*    grid-template-columns: 1fr;*/
    /*}*/

    /*.wrapper_cart_items .wrapper_every_items .wrapper_preview_item .wrapper_item_image {*/
    /*    width: 120px;*/
    /*}*/

    /*.wrapper_cart_items .wrapper_every_items .wrapper_preview_item {*/
    /*    grid-template-columns: 120px 1fr;*/
    /*}*/

    /*.wrapper_order_preview .wrapper_delivery_description .wrapper_security_pay_icons {*/
    /*    grid-template-columns: repeat(2, 1fr);*/
    /*}*/

    /*.wrapper_order_sum .title_wrapper {*/
    /*    font-size: 24px;*/
    /*}*/
}
