.promocode_list_wrapper{
     background: #FAFAFA;
     display: grid;
     grid-template-columns: repeat(4, 1fr) 50px;
    text-align: center;
 }

.promocode_list_wrapper span,
.promocode_list span{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 12px 24px;
    margin: auto;
}


.promocode_list{
    display: grid;
    grid-template-columns: repeat(4, 1fr) 50px;
    text-align: center;
    border-bottom: #E9E9E9 1px solid;
}

.delete_icon{
    display: grid;
    align-content: center;
}

.delete_icon img{
    cursor: pointer;
}
