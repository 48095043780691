.wrapper_navigation_navbar {
    background: #F9F9F9;
    padding: 32px 40px;
    display: grid;
    min-height: 100vh;
    grid-auto-rows: max-content;
    grid-gap: 20px 0;
    height: 100%;
}

.wrapper_navigation_navbar a {
    font-size: 19px;
    line-height: 200%;
    color: #333
}

.wrapper_navigation_navbar .nav_bar_active_link {
    color: #C69C6D;
}

.wrapper_navigation_navbar .nav_bar_active_link:before {
    content: "\2014";
    padding-right: 4px;
}
