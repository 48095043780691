.wrapper_content .every_word_wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: 20px;
    padding-top: 40px;
}


.list_categories,
.list_brands {
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 40px;
}

.list_categories {
    grid-template-columns: repeat(4, 1fr);
}

.delete_mobili {
    display: block;
}

.title_section {
    width: 220px;
    display: grid;
    left: 22px;
    top: 2600px;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    letter-spacing: 0.02em;
    color: #000000;
}

.wrapper_brands_page .wrapper_preview_section {
    grid-template-columns: 192px auto;
    width: fit-content;
}

.wrapper_every_brands {
    width: auto;
    height: 305px;
    overflow: hidden;
    background: #F9F9F9;
    position: relative;
}

.wrapper_all_brands {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    width: auto;
}

.wrapper_preview_apper_brands_image {
    top: 37px;
}

.wrapper_preview_bb_italia_brands_image {
    top: 75px;
}

.wrapper_preview_bonaldo_brands_image {
    top: 97px;
}

.wrapper_preview_cappellini_brands_image {
    top: 120px;
}

.wrapper_preview_cassina_brands_image {
    top: 120px;
}

.wrapper_preview_davide_groppi_brands_image {
    right: -16px;
    top: -1px;
}

.wrapper_preview_edra_brands_image {
    top: 75px;
}

.wrapper_preview_fiam_brands_image {
    top: 63px;
}

.wrapper_preview .wrapper_desc .apper_content {
    width: 161px;
    height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    position: relative;
    z-index: 99;
    top: 131px;
}

.wrapper_preview .wrapper_desc .bb_italia_content {
    width: 161px;
    height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    position: relative;
    z-index: 99;
    top: 18px;
}

.wrapper_preview .wrapper_desc .bonaldo_content {
    width: 161px;
    height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    position: relative;
    z-index: 99;
    top: 134px;
    left: 0;
}

.wrapper_preview .wrapper_desc .cappellini_content {
    width: 161px;
    height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    position: relative;
    z-index: 99;
    top: 37px;
}

.wrapper_preview .wrapper_desc .cassina_content {
    width: 161px;
    height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    position: relative;
    z-index: 99;
    top: 67px;
}

.wrapper_preview .wrapper_desc .davide_groppi_content {
    width: 161px;
    height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    position: relative;
    z-index: 99;
    top: 49px;
}

.wrapper_preview .wrapper_desc .edra_content {
    width: 161px;
    height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    position: relative;
    z-index: 99;
    top: 130px;
}

.wrapper_preview .wrapper_desc .fiam_content {
    width: 161px;
    height: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #828282;
    position: relative;
    z-index: 99;
    top: 82px;
}

.wrapper_preview .wrapper_title .apper_title {
    width: 132px;
    height: 44px;
    left: -2px;
    top: -44px;
    font-size: 38px;
    font-weight: 700;
    position: relative;
    z-index: 99;
    font-family: Open Sans;
}

.wrapper_preview .wrapper_title .bb_italia_title {
    width: 132px;
    height: 44px;
    left: -42px;
    top: -82px;
    font-size: 38px;
    font-weight: 700;
    position: relative;
    z-index: 99;
    font-family: Open Sans;
}

.wrapper_preview .wrapper_title .bonaldo_title {
    width: 132px;
    height: 44px;
    left: 5px;
    top: -49px;
    font-size: 38px;
    font-weight: 700;
    position: relative;
    z-index: 99;
    font-family: Open Sans;
}

.wrapper_preview .wrapper_title .cappellini_title {
    width: 132px;
    height: 44px;
    left: -40px;
    top: -84px;
    font-size: 38px;
    font-weight: 700;
    position: relative;
    z-index: 99;
    font-family: Open Sans;
}

.wrapper_preview .wrapper_title .cassina_title {
    width: 132px;
    height: 44px;
    left: -3px;
    top: -81px;
    font-size: 38px;
    font-weight: 700;
    position: relative;
    z-index: 99;
    font-family: Open Sans;
}

.wrapper_preview .wrapper_title .davide_groppi_title {
    width: 132px;
    height: 44px;
    left: -20px;
    top: -81px;
    font-size: 38px;
    font-weight: 700;
    position: relative;
    z-index: 99;
    font-family: Open Sans;
}

.wrapper_preview .wrapper_title .edra_title {
    width: 132px;
    height: 44px;
    left: 4px;
    top: -48px;
    font-size: 38px;
    font-weight: 700;
    position: relative;
    z-index: 99;
    font-family: Open Sans;
}

.wrapper_preview .wrapper_title .fiam_title {
    width: 132px;
    height: 44px;
    left: -48px;
    top: -58px;
    font-size: 38px;
    font-weight: 700;
    position: relative;
    z-index: 99;
    font-family: Open Sans;
}

.wrapper_catalog_main_content {
    display: grid;
    margin-bottom: 60px;
    min-height: calc(100vh - 560px);
    grid-gap: 20px;
}

.wrapper_content .every_word_wrapper .publishing_content_wrapper {
    display: grid;
    cursor: pointer;
    grid-template-columns: repeat(auto-fit, 195px);
    align-content: center;
    grid-gap: 16px;
    font-family: Playfair Display;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 200%;
    letter-spacing: 0.02em;
    color: #000000;
    padding-top: 10px;
}

.wrapper_content .every_word_wrapper .publishing_content_wrapper a {
    font-weight: 500;
    text-decoration: none;
    overflow: hidden;
    font-size: 18px;
    line-height: 120%;
    color: #000;
}

.brands_page_title {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #333;
    margin-bottom: 12px;
}

.link {
    font-size: 13px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #A7A7A7;
    margin-right: 20px;
}

.text_brands {
    width: 381px;
    height: 22px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 42px;
}

.text {
    height: 32px;
    font-family: Playfair Display;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #000000;
    margin-bottom: 17px;
}

@media (max-width: 1600px) {
    .wrapper_all_brands {
        grid-template-columns: repeat(3, 1fr);
    }

    .wrapper_content .every_word_wrapper .publishing_content_wrapper {
        grid-template-columns: repeat(auto-fit, 200px)
    }

    .wrapper_preview .wrapper_title .bb_italia_title {
        left: -40px;
    }

    .wrapper_preview .wrapper_title .cappellini_title {
        left: -40px;
    }

    .wrapper_preview .wrapper_title .cassina_title {
        left: -5px;
    }

    .wrapper_preview .wrapper_title .davide_groppi_title {
        left: -20px;
    }

    .wrapper_preview .wrapper_title .fiam_title {
        left: -43px;
    }
}

@media (max-width: 1160px) {
    .wrapper_content .every_word_wrapper {
        display: grid;
        grid-template-columns: auto 1fr;
    }

    .title_section {
        height: 64px;
    }

    .wrapper_content .every_word_wrapper .publishing_content_wrapper {
        grid-template-columns: repeat(auto-fit, 182px)
    }

    .text {
        display: none;
    }

    .text_brands {
        margin-bottom: 26px;
    }

    .wrapper_every_brands {
        width: auto;
    }

    .wrapper_all_brands {
        grid-template-columns: repeat(3, auto);
    }

    .wrapper_preview .wrapper_desc .apper_content {
        left: -25px;
    }

    .wrapper_preview .wrapper_desc .bb_italia_content {
        left: -25px;
    }

    .wrapper_preview .wrapper_desc .bonaldo_content {
        left: -25px;
    }

    .wrapper_preview .wrapper_desc .cappellini_content {
        left: -25px;
    }

    .wrapper_preview .wrapper_desc .cassina_content {
        left: -25px;
    }

    .wrapper_preview .wrapper_desc .davide_groppi_content {
        left: -25px;
    }

    .wrapper_preview .wrapper_desc .edra_content {
        left: -25px;
    }

    .wrapper_preview .wrapper_desc .fiam_content {
        left: -25px;
    }

    .wrapper_preview .wrapper_title .apper_title {
        left: -25px;
    }

    .wrapper_preview .wrapper_title .bb_italia_title {
        left: -65px;
    }

    .wrapper_preview .wrapper_title .bonaldo_title {
        left: -25px;
    }

    .wrapper_preview .wrapper_title .cappellini_title {
        left: -65px;
    }

    .wrapper_preview .wrapper_title .cassina_title {
        left: -25px;
    }

    .wrapper_preview .wrapper_title .davide_groppi_title {
        left: -45px;
    }

    .wrapper_preview .wrapper_title .edra_title {
        left: -25px;
    }

    .wrapper_preview .wrapper_title .fiam_title {
        left: -65px;
    }
}

@media (max-width: 900px) {
    .wrapper_all_brands {
        display: grid;
        grid-template-columns: repeat(2, auto);
        margin-top: 20px;
    }

    .wrapper_every_brands {
        width: auto;
    }
}

@media (max-width: 768px) {
    .text {
    }

    .title_section {
        padding-bottom: 20px;
    }

    .wrapper_content .every_word_wrapper {
        display: grid;
        grid-template-columns: auto;
    }

    .wrapper_content .every_word_wrapper .publishing_content_wrapper {
        grid-template-columns: repeat(auto-fit, 194px)
    }

    .wrapper_every_brands {
        width: auto;
    }

    .wrapper_all_brands {
        grid-template-columns: repeat(2, auto);
    }

    .wrapper_preview .wrapper_desc .apper_content {
        left: 25px;
    }

    .wrapper_preview .wrapper_desc .bb_italia_content {
        left: 25px;
    }

    .wrapper_preview .wrapper_desc .bonaldo_content {
        left: 25px;
    }

    .wrapper_preview .wrapper_desc .cappellini_content {
        left: 25px;
    }

    .wrapper_preview .wrapper_desc .cassina_content {
        left: 25px;
    }

    .wrapper_preview .wrapper_desc .davide_groppi_content {
        left: 25px;
    }

    .wrapper_preview .wrapper_desc .edra_content {
        left: 25px;
    }

    .wrapper_preview .wrapper_desc .fiam_content {
        left: 25px;
    }

    .wrapper_preview .wrapper_title .apper_title {
        left: 25px;
    }

    .wrapper_preview .wrapper_title .bb_italia_title {
        left: -24px;
    }

    .wrapper_preview .wrapper_title .bonaldo_title {
        left: 25px;
    }

    .wrapper_preview .wrapper_title .cappellini_title {
        left: -16px;
    }

    .wrapper_preview .wrapper_title .cassina_title {
        left: 25px;
    }

    .wrapper_preview .wrapper_title .davide_groppi_title {
        left: 5px;
    }

    .wrapper_preview .wrapper_title .edra_title {
        left: 25px;
    }

    .wrapper_preview .wrapper_title .fiam_title {
        left: -24px;
    }
}

@media (max-width: 570px) {
    .wrapper_all_brands {
        display: grid;
        grid-template-columns: repeat(1, auto);
        margin-top: 20px;
    }

    .wrapper_every_brands {
        width: auto;
        margin: 0 50px 0 50px;
    }
}

@media (max-width: 480px) {
    .text_brands {
        display: contents;
    }

    .vector {
        margin-right: 5px;
        margin-left: 5px;
    }

    .wrapper_every_brands {
        width: auto;
        margin: 0;
        padding: 0;
    }

    .wrapper_all_brands {
        display: grid;
        grid-template-columns: repeat(1, auto);
        margin-top: 20px;
    }

    .wrapper_preview .wrapper_title .bb_italia_title {
        left: -9px;
    }

    .wrapper_preview .wrapper_title .cappellini_title {
        left: -16px;
    }

    .wrapper_preview .wrapper_title .davide_groppi_title {
        left: 11px;
    }

    .wrapper_preview .wrapper_title .fiam_title {
        left: -9px;
    }

    .wrapper_content .every_word_wrapper .publishing_content_wrapper {
        grid-template-columns: repeat(auto-fit, 138px)
    }
}