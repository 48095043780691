.calculate_order_wrapper {
    height: 500px;
    overflow-y: auto;
}

.product_wrapper {
    display: grid;
    grid-template-rows: max-content auto;
    grid-template-columns: 1fr;
    margin-top: 16px;
}

.product_wrapper:last-child {
    margin-bottom: 16px;
}

.product_wrapper .product_top {
    display: grid;
    grid-template-rows: max-content max-content;
    grid-template-columns: 1fr 5fr;
    grid-gap: 0 24px;
}

.product_wrapper .product_top > img {
    grid-row: 1/-1;
    width: 100%;
}

.product_name .pr_name {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
}

.product_info_top .pr_quantity {
    font-weight: bold;
    font-size: 12px;
    line-height: 25px;
}

.product_info_top .pr_price {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-right: 12px;
}

.product_name .pr_brand {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #828181;
}

.product_top .product_info_top {
    display: grid;
    grid-template-columns: 1fr 2fr auto;
}

.product_info_bot {
    display: grid;
    width: 50%;
    grid-template-columns: max-content max-content;
}

.product_info_bot .product_prop {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
    grid-gap: 8px;
}

.product_prop .prop_info{
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: 8px;
    padding: 4px 8px;
}

.product_prop > span {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
}

.prop_info span {
    font-size: 12px;
    line-height: 16px;
    color: #333333;
}

.prop_info img {
    width: 24px;
}

.calc_price {
    display: grid;
    grid-template-columns: 280px;
    grid-gap: 8px;
    margin-top: 16px;
}

.calc_price span {
    font-weight: 600;
    font-size: 14px;
    line-height: 179%;
    color: #292929;
}

.calc_price input {
    background: #F9F9F9;
    border: 1px solid #8D99AE;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 18px;
}

.popup_comment {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    border-top: #CECECE 1px solid;
    padding: 12px 0;
}

.popup_comment span {
    font-weight: 600;
    font-size: 14px;
    line-height: 179%;
    color: #292929;
}

.popup_comment textarea {
    background: #F9F9F9;
    border: 1px solid #8D99AE;
    box-sizing: border-box;
    border-radius: 5px;
    resize: none;
    padding: 12px 16px;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
}

.popup_footer {
    display: grid;
    grid-template-columns: 1fr auto;
}

.popup_footer .ft_price {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
}

.popup_footer .ft_price {
    align-self: center;
}

.popup_footer .ft_sale {
    font-size: 12px;
    line-height: 16px;
    color: #333333;
}

.popup_footer .popup_footer_price {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
}
