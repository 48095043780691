@import "variables";
@import "global";

.login {
    display: grid;
    height: 100vh;
    width: 100%;
    background-color: $color-white;

    &__wrapper {
        justify-self: center;
        align-self: center;
        width: 420px;
    }

    &__logo {
        display: flex;
        justify-content: center;
        margin-bottom: 48px;
    }

    &-form {
        display: grid;
        grid-gap: 16px;

        &__input {
            border: 1px solid #333333;
            color: #333333;
            opacity: 0.3;

            &_active {
                opacity: 1;
            }

            &:focus {
                opacity: 1;
            }
        }

        &__submit {
            color: #C69C6D;
            border: 1px solid #333333;
            background-color: #333333;

            &:hover {
                color: $color-black-dark;
                background-color: $color-white;
            }
        }

        &__input, &__submit {
            padding: 12px 16px;
            font-family: $font;
            font-weight: 600;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 19px;
            border-radius: 2px;
            transition: $transition;
        }
    }

    &__text {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: rgba($color: $color-black-dark, $alpha: 0.16);
    }

    &__link {
        color: rgba($color: $color-black-dark, $alpha: 0.16);
        text-decoration: underline;

        &:hover {
            color: rgba($color: $color-black-dark, $alpha: 0.16);
        }
    }


}
