.user_order_wrapper_tabs_container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 2rem;
    align-items: center;
}

.user_order_wrapper_tab {
    margin: 0 0 1rem;
    font-family: 'Playfair Display', sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333333;
    padding-bottom: 6px;
    border-bottom: 3px solid #fff;
    transition: .3s all;
    cursor: pointer;
}

.user_order_wrapper_tab_active {
    border-bottom: 3px solid #333333;
}

.user_order_current_orders_buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
    grid-gap: 1rem;
}

.user_order_current_orders_buttons a {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    padding: 1rem 5rem;
    background: #C69C6D;
    border: 1px solid #C69C6D;
    color: #FFFFFF;
}

.user_order_current_orders_buttons a:nth-child(2) {
    border: 1px solid #333333;
    color: #333333;
    background: #ffffff;
}

.user_order_current_orders span {
    font-family: 'Open Sans', sans-serif;
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
}

.user_order_current_orders hr {
    height: 1px;
    width: 100%;
    background: #D3D3D3;
    margin: 2.75rem 0 20px;
}

.user_order_item_container {
    border: 1px solid #D3D3D3;
    margin-bottom: 1.5rem;
}

.user_order_item_container h5 {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #828282;
    margin: 0 0 2px;
}

.user_order_item_container h4 {
    font-family: 'Playfair Display', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #333333;
    margin: 0 0 20px;
    text-decoration: underline;
}

.user_order_item_container_product_price {
    padding-right: 1rem;
}

.user_order_item_container_product_price span {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
}

.user_order_item_container_product {
    display: grid;
    grid-template-columns: 5.5rem 1fr max-content;
    grid-gap: 20px;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #F9F9F9;
}

.user_order_item_container_product:last-of-type {
    padding-bottom: 20px;
    border-bottom: none;
}

.user_order_item_container_product_footer {
    background: #F9F9F9;
    padding: 1.5rem;
}

.user_order_item_container_product_footer_price {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: 12px;
    align-items: center;
    margin-bottom: 1rem;
}

.user_order_item_container_product_image {
    display: grid;
    align-items: center;
    background: rgba(196, 196, 196, 0.1);
}

.user_order_item_container_product_image img {
    width: 5.5rem;
    height: 90px;
    object-fit: cover;
}

.user_order_item_container_product_footer_price p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    margin: 0;
    align-self: start;
}

.user_order_item_container_product_properties {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.user_order_item_container_product_properties_item span {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    display: inline-block;
    width: max-content;
    width: -moz-max-content;
    margin-bottom: 10px;
    color: #000000;
}

.user_order_item_container_product_properties_item_container {
    border: 1px solid #D3D3D3;
    padding: 4px;
    display: flex;
    align-items: center;
    margin: 0 1.5rem 1rem 0;

}

.user_order_item_container_product_properties_item_container p {
    margin: 0 0 0 8px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.user_order_item_container_product_properties_item_container span {
    margin: 0 0 0 1rem;
    display: inline-block;
    width: max-content;
    width: -moz-max-content;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

@media (max-width: 600px) {
    .user_order_current_orders_buttons {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        margin-bottom: 1rem;
    }

    .user_order_item_container_product_price > span {
        font-size: 16px;
    }

    .user_order_item_container_product_footer_price {
        grid-template-columns: max-content 1fr;
        grid-gap: 12px;
        align-items: center;
        margin-bottom: 1rem;
    }

    .user_order_item_container_product_footer_price p:nth-child(3), .user_order_item_container_product_properties_mobile {
        grid-column: 1/-1;
    }

    .user_order_item_container_product {
        padding: 0 12px;
        grid-gap: 10px 20px;
        grid-template-columns: 1fr 1fr;
    }

    .user_order_item_container_product_price {
        padding: 0;
    }

    .user_order_item_container_product_properties_item_container {
        margin: 0 .5rem .5rem 0;
    }

    .user_order_item_container_product_image img {
        height: 150px;
        width: 100%;
    }

}