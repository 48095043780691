.wrapper_preview_category_section {
    display: grid;
    grid-template-columns: 240px 1fr;
    height: 400px;
    box-sizing: initial;
    margin: 40px 0;
}

.wrapper_preview_category_section .preview_category_title .wrapper_title {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #333
}

.wrapper_preview_category_section .preview_category_title .wrapper_description {
    font-size: 14px;
    line-height: 19px;
    color: #000
}

.wrapper_preview_category_section .preview_category_title .preview_category_link {
    margin-top: 36px;
}

.wrapper_preview_category_section .preview_category_title .preview_category_link button {
    padding: 12px 20px;
}

/*slider*/
.wrapper_preview_category_section .preview_category_slider {
    position: relative;
    margin: 0 40px;
    display: block;
    height: inherit;
}

.wrapper_preview_category_section .wrapper_title {
    font-size: 24px;
    height: max-content;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333
}

.wrapper_preview_category_section .preview_category_slider .slick-slider {
    top: 0;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
}

.wrapper_preview_category_section .preview_category_slider .slick-next,
.wrapper_preview_category_section .preview_category_slider .slick-prev {
    background: #C69C6D;
    z-index: 10;
    width: 32px;
    height: 52px;
}

.wrapper_preview_category_section .preview_category_slider .slick-disabled {
    background: #C4C4C4;
}

.wrapper_preview_category_section .preview_category_slider .slick-next:before {
    content: url('../../assets/slider_vector_next.svg');
}

.wrapper_preview_category_section .preview_category_slider .slick-prev:before {
    content: url('../../assets/slider_vector_prev.svg');
}

.wrapper_preview_category_section .preview_category_slider .slick-prev:before,
.wrapper_preview_category_section .preview_category_slider .slick-next:before {
    opacity: 1;
    font-size: 16px;
}

/*slider*/
@media (max-width: 768px) {
    /*slider*/
    .wrapper_preview_category_section {
        height: auto;
        padding: 80px 0 56px;
    }

    .wrapper_preview_category_section .preview_category_title {
        grid-column: 1/-1;
        text-align: center;
    }

    .wrapper_preview_category_section .preview_category_link_mobile {
        grid-column: 1/-1;
        justify-self: center;
    }

    .wrapper_preview_category_section .preview_category_link_mobile button {
        padding: 12px 20px;
    }

    .wrapper_preview_category_section .preview_category_slider {
        grid-row: 2;
        height: 366px;
        grid-column: 1/-1;
    }

    /*slider*/
}

@media (max-width: 480px) {
    /*slider*/
    .wrapper_preview_category_section .preview_category_slider {
        margin: 0 12px;
        max-height: 340px;
    }

    /*slider*/
}
