@import "variables";

.stats {
    margin-left: 255px;
    background-color: $color-white-light;

    &__wrapper {
        background-color: $color-white;
    }

    &__headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 32px 39px 16px 44px;
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 1px solid $color-gray;
    }
      
    &__date {
        font-family: $font;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.374px;
        color: $color-black-dark;
        box-sizing: border-box;
        border-radius: 4px;
        border: 2px solid $color-black-dark;
        position:relative;
        padding: 4px 24px;
        background:
          $color-white
          url("../../assets/stats-icon-calendar.svg")
          right 1rem
          center
          no-repeat;
        
        cursor: pointer;
    }

    ::-webkit-datetime-edit-text {
        opacity: 0;
    }

    ::-webkit-clear-button,
    ::-webkit-inner-spin-button {
        display: none;
    }

    ::-webkit-calendar-picker-indicator {
        position: absolute;
        width: 2.5rem;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
    }

    &-box {
        border-bottom: 1px solid $color-gray;

        &__head, &__body {
            display: grid;
            grid-template-columns: 1fr 200px 150px;
            padding-right: 42px;
        }

        &__head {
            padding-top: 8px;
            padding-bottom: 9px;    
            padding-left: 50px;
        }

        &__body {
            padding-left: 85px;

            &:last-of-type {
                padding-bottom: 19px;
            }
        }

        &__title, &__text {
            font-family: $font;
            font-size: 16px;
            line-height: 41px;
            letter-spacing: 0.374px;
            color: $color-black-dark;

            &:nth-child(2), &:nth-child(3) {
                text-align: right;
            }
        }

        &__title {
            font-weight: bold;
        }

        &__text {
            font-weight: 600;
        }

        &__arrow {
            margin-right: 23px;
            transition: $transition;
        }

        &.active {
            .stats-box {
                &__arrow {
                    transform: rotate(180deg);
                }
            }
        }
    }
}