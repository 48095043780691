@import "variables";

.languages {
    margin-left: 255px;
    min-height: 100vh;
    background-color: $color-white-light;

    &__title {
        padding: 32px 24px;
    }

    &-info {
        margin-bottom: 24px;
        border-radius: 5px;
        background-color: $color-white;

        &__head {
            padding: 12px 16px;
            display: grid;
            grid-template-columns: 110px 1fr;
            grid-template-rows: 1fr;
            background-color: $color-white-dark;
        }

        &__head-long {
            padding: 12px 16px;
            display: grid;
            grid-template-columns: 220px 1fr;
            grid-template-rows: 1fr;
            background-color: $color-white-dark;
        }

        &__text {
            margin: 0;
            font-family: $font;
            font-weight: 600;
            font-size: 14px;
            line-height: 25px;
            color: $color-gray-light-dark;
        }

        &-list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            &__item {
                display: grid;
                align-items: center;
                grid-template-columns: 1fr repeat(2, 36px);
                grid-template-rows: 1fr;
                padding: 16px 24px;
                border-bottom: 1px solid $color-white-gray;
                cursor: default;
            }

            &__text {
                margin: 0;
                font-family: $font;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: $color-black;
            }
        }
    }

    &-add {
        border-radius: 5px;
        background-color: $color-white;
        padding: 16px 24px 24px;

        &__title {
            margin-bottom: 16px;
            font-family: $font;
            font-weight: bold;
            font-size: 20px;
            line-height: 41px;
            letter-spacing: 0.374px;
            color: $color-black-light;
        }

        &__form {
            display: flex;
            flex-direction: column;
        }

        &__label {
            display: flex;
            position: relative;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            max-width: 160px;
            border: 1px solid $color-white-gray-dark;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 27px 28px 16px;
            margin-bottom: 16px;
        }

        &__image {
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
        }

        &__file {
            display: none;
        }

        &__icon {
            margin-bottom: 23px;
        }

        &__download {
            font-family: $font;
            font-weight: 600;
            font-size: 12px;
            line-height: 21px;
            color: $color-gray-dark-light;
        }

        &__text {
            margin: 0;
            font-family: $font;
            font-weight: 600;
            font-size: 14px;
            line-height: 25px;
            color: $color-gray-dark;
        }

        & .g-input {
            margin-bottom: 16px;
        }

        &__block {
            display: flex;
            justify-content: flex-end;
        }

        & .g-add {
            align-self: flex-end;

            &_margin {
                margin-right: 16px;
            }
        }

        &__edit {
            display: flex;
            justify-content: flex-end;

            .g-add {
                &:first-of-type {
                    margin-right: 16px;
                }
            }
        }
    }
}
