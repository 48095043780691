.wrapper_catalogue_goods {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 22px;
}

.wrapper_catalogue_title {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.wrapper_catalogue_title > div:last-child {
    justify-self: end;
    align-self: center;
}

@media (max-width: 1300px) {
    .wrapper_catalogue_goods {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1150px) {
    .wrapper_catalogue_goods {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 900px) {
    .wrapper_catalogue_goods {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 700px) {
    .wrapper_catalogue_goods {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 450px) {
    .wrapper_catalogue_goods {
        grid-template-columns: repeat(1, 1fr);
    }
}