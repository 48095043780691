.wrapper_info_about_item {
    display: grid;
    grid-template-columns: 2fr 1fr 0.5fr;
    padding: 20px 0 0;
    grid-gap: 12px;
}

.wrapper_info_about_item .info_about_item .wrapper_brand {
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #828282;
}

.wrapper_info_about_item .info_about_item .wrapper_item_name {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #333
}

.wrapper_info_about_item .info_about_item .wrapper_item_description {
    font-size: 14px;
    line-height: 19px;
    color: #333
}

.wrapper_info_about_item .info_about_item .wrapper_item_description .more_details {
    color: #C69C6D;
    cursor: pointer;
    text-decoration: underline;
}

.wrapper_info_about_item .info_about_item .wrapper_item_price {
    display: grid;
    margin-top: 12px;
    grid-gap: 12px;
}

.wrapper_info_about_item .info_about_item .wrapper_item_price .price_title {
    font-size: 24px;
    line-height: 33px;
    color: #333
}

.wrapper_info_about_item .info_about_item .wrapper_find_out_the_price .active_favorite {
    background: #C69C6D;
}

.wrapper_info_about_item .info_about_item .wrapper_find_out_the_price .active_favorite:hover {
    background: #fff;
}

.wrapper_info_about_item .info_about_item .wrapper_find_out_the_price .active_favorite:hover svg path {
    fill: #C7AF7F;

}

.wrapper_info_about_item .info_about_item .wrapper_find_out_the_price .active_favorite svg path {
    fill: #fff
}

.wrapper_info_about_item .info_about_item .wrapper_find_out_the_price button {
    padding: 16px 0;
    font-size: 14px;
    line-height: 19px;
    display: grid;
}

.wrapper_brands_page .wrapper_most_popular {
    display: grid;
    padding: 0;
    grid-template-columns: 1fr;
}

.wrapper_info_about_item .info_about_item .wrapper_find_out_the_price button svg {
    justify-self: center;
    align-self: center;
}

.wrapper_info_about_item .info_about_item .wrapper_adding_to_cart {
    margin-top: 12px;
}

.wrapper_info_about_item .info_about_item .wrapper_adding_to_cart .adding_to_cart {
    display: grid;
    grid-template-columns: 1fr max-content;
    width: 100%;
    max-width: 310px;
    background: #333;
}

.wrapper_info_about_item .info_about_item .wrapper_adding_to_cart .adding_to_cart .item_quantity {
    padding: 16px 12px;
    display: grid;
    grid-gap: 0 12px;
    grid-auto-flow: column;
    background: #535353;
    color: #fff
}

.wrapper_info_about_item .info_about_item .wrapper_adding_to_cart .adding_to_cart .item_quantity .quantity_changer {
    cursor: pointer;
    height: 100%;
}

.wrapper_info_about_item .info_about_item .wrapper_adding_to_cart .adding_to_cart .adding_to_cart_btn {
    text-align: center;
    padding: 16px 0;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #fff
}

/*slider*/
.horizontal_slider {
    display: none;
}

.vertical_slider {
    display: grid;
    grid-template-columns: 89px 1fr;
    grid-gap: 20px;
    max-height: 440px;
}

.second_slider {
    display: flex;
    justify-content: center;
}

.vertical_slider img {
    height: 440px;
    object-fit: contain;
    max-width: 600px;
}

.vertical_slider .slick-slider:first-child {
    margin-top: 14px;
}

.vertical_slider .slick-slider {
    margin: 0
}

.viewer-container img {
    background-color: white;
}

.unique_items_page > .vertical_slider .slick-slider .slick-list .slick-track {
    height: auto !important;
}

.vertical_slider .slick-slider .slick-list .slick-track .slick-slide {
    width: auto !important;
}

.vertical_slider .slick-slider:first-child img {
    transition: .5s;
    width: 80px;
    margin-left: 4px;
    object-fit: cover;
    box-sizing: border-box;
}

.vertical_slider .slick-slider:first-child div {
    cursor: pointer;
}

.vertical_slider .slick-slider:first-child div img {
    height: 80px;
    width: 80px;
}

.vertical_slider .slick-slider:first-child .slick-initialized {
    width: 89px;
}

.vertical_slider .slick-slider:first-child .slick-prev {
    top: -10px !important;
    bottom: unset !important;
}

.vertical_slider .slick-slider:first-child .slick-next:before,
.vertical_slider .slick-slider:first-child .slick-prev:before {
    font-size: 20px;
    line-height: 1.2px;
}

.vertical_slider .slick-slider:first-child .slick-next:before {
    content: '∨';
    transform: rotate(90deg) !important;
}

.vertical_slider .slick-slider:first-child .slick-prev:before {
    top: 100%;
    content: '∧';
}

.vertical_slider .slick-slider:last-child img {
    object-fit: contain;
}

.vertical_slider .slick-slider:first-child .slick-prev,
.vertical_slider .slick-slider:first-child .slick-next {
    width: 100%;
    background: #000;
    border-radius: 3px;
    left: 0;
}

.vertical_slider .slick-slider:first-child .slick-current img {
    opacity: 1;
    padding: 0;
    border: none;
}

.vertical_slider .slick-slider:first-child .slick-next {
    bottom: -27px !important;
    top: unset !important;
}

.vertical_slider .slick-slider:first-child .slick-prev {
    top: 97.5%;
}

/*slider*/

.unique_items_page .wrapper_about_us_info {
    padding: 30px 60px;
    background: #333;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 60px 0;
    grid-gap: 0 12px;
}

.unique_items_page .wrapper_about_us_info .every_about_us_info {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-content: center;
    grid-gap: 0 12px;
}

.unique_items_page .wrapper_about_us_info .every_about_us_info a {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-content: center;
    grid-gap: 0 12px;
}

.unique_items_page .wrapper_about_us_info .every_about_us_info .main_icon {
    grid-row: 1/-1;
    align-self: center;
}

.unique_items_page .wrapper_about_us_info .every_about_us_info .wrapper_title {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #fff;
}

.unique_items_page .wrapper_about_us_info .every_about_us_info .wrapper_title img {
    vertical-align: middle;
    padding-left: 4px;
}

.unique_items_page .wrapper_about_us_info .every_about_us_info .wrapper_desc {
    font-size: 14px;
    line-height: 100%;
    margin-top: 4px;
    color: #fff
}

.unique_items_page .wrapper_about_us_info .border_block {
    border-left: 1px solid #828282;
    padding-left: 12px;
}

.unique_items_page .wrapper_info_about_product {
    background: #F9F9F9;
    padding: 52px 60px;
    grid-gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 80px;
}

.unique_items_page .wrapper_info_about_product .wrapper_title {
    display: grid;
}

.unique_items_page .wrapper_info_about_product .wrapper_description,
.unique_items_page .wrapper_info_about_product .wrapper_title span:first-child {
    font-size: 16px;
    line-height: 22px;
    color: #828282;
}

.unique_items_page .wrapper_info_about_product .wrapper_description {
    margin-top: 30px;
}
.unique_items_page .wrapper_info_about_product .wrapper_description *{
    height: auto !important;
}

.unique_items_page .wrapper_info_about_product .wrapper_title .main_title {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #333
}

.unique_items_page .wrapper_info_about_product .wrapper_shipping_info {
    margin-top: 30px;
}

.unique_items_page .wrapper_info_about_product .wrapper_shipping_info a {
    font-size: 14px;
    text-decoration: underline;
    line-height: 19px;
    color: #C69C6D;
}

.unique_items_page .wrapper_info_about_product .wrapper_main_info {
    margin-top: 30px;
}

.unique_items_page .wrapper_info_about_product .wrapper_main_info .every_main_info {
    border-bottom: 1px solid #D3D3D3;
    padding: 20px 0;
    display: grid;
    grid-auto-flow: column;
}

.unique_items_page .wrapper_info_about_product .wrapper_main_info .every_main_info span:first-child {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #828282;
}

.unique_items_page .wrapper_info_about_product .wrapper_main_info .every_main_info span:last-child {
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #333
}

.unique_items_page .wrapper_preview_liked_goods_section .wrapper_preview_category_section {
    padding: 40px 0;
}

.unique_items_page .wrapper_preview_liked_goods_section .wrapper_title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333
}

.dropdown {
    width: 400px;
}

.dropdown_img {
    width: 150px;
}

.wrapper_property_color_options {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.wrapper_dropdown_block {
    display: flex;
    justify-content: space-between;
}

.wrapper_property {
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    margin-left: 5px;
}

.wrap_properties_name {
    margin: 10px 0 0 0;
}

/*.MuiSelect-select {*/
/*    display: flex !important;*/
/*    align-items: center;*/
/*    border: 1px solid #D3D3D3 !important;*/
/*    padding: 2px !important;*/
/*}*/

/*.MuiInput-underline::before {*/
/*    border-bottom: none !important;*/
/*}*/

/*.MuiInput-underline::after {*/
/*    border-bottom: none !important;*/
/*}*/

/*.MuiListItem-gutters {*/
/*    padding-right: 30px !important;*/
/*}*/

/*.MuiMenu-paper {*/
/*    max-height: 320px !important;*/
/*}*/

/*.MuiFormControl-root {*/
/*    margin-top: 20px !important;*/
/*}*/

/*.MuiInputLabel-formControl {*/
/*    top: -8px !important;*/
/*}*/

.show_options {
    margin-top: 12px;
}

.wrapper_select_input {
    position: relative;
}

.wrapper_select_input .wrapper_category_name {
    color: rgba(0, 0, 0, 0.54)
}

.wrapper_select_input .wrapper_preview_category {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: max-content;
    grid-gap: 0 12px;
    padding: 8px 30px 8px 8px;
    cursor: pointer;
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
}

.wrapper_select_input .wrapper_preview_category > span {
    font-size: 16px;
    line-height: 22px;
    width: 200px;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.wrapper_every_params active {
    overflow: hidden;
}

.wrapper_select_input .wrapper_category_params .wrapper_every_params img,
.wrapper_select_input .wrapper_preview_category img:first-child {
    width: 22px;
    height: 22px;
}

.wrapper_select_input .wrapper_preview_category img:last-child {
    justify-self: end;
}

.wrapper_select_input .wrapper_category_params {
    position: absolute;
    background: #fff;
    width: 100%;
    overflow: auto;
    margin-top: 12px;
    cursor: pointer;
    max-height: 200px;
    overflow-y: scroll;
    z-index: 1000;
    border: 1px solid #D3D3D3;
    padding: 8px;
    box-sizing: border-box;
    display: grid;
    grid-gap: 12px 0;
}

.wrapper_select_input .wrapper_category_params .wrapper_every_params {
    display: grid;
    grid-gap: 0 20px;
    grid-auto-flow: column;
    grid-template-columns: max-content;
    align-items: center;
}

.wrapper_select_input .wrapper_category_params .wrapper_every_params span {
    font-size: 16px;
    line-height: 22px;
    color: #333
}

.wrapper_select_input .wrapper_category_params .active img {
    border: 2px solid #C69C6D;
    box-sizing: border-box;
}

.wrapper_select_input .wrapper_category_params .active span {
    color: #C69C6D
}


@media (max-width: 1044px) {
    .unique_items_page .wrapper_about_us_info {
        grid-template-columns: 1fr;
        padding: 20px 12px;
    }

    .unique_items_page .wrapper_about_us_info .every_about_us_info {
        border-bottom: 1px solid #828282;
        padding: 12px 0;
    }

    .unique_items_page .wrapper_about_us_info .every_about_us_info:last-child {
        border-bottom: none
    }

    .unique_items_page .wrapper_about_us_info .every_about_us_info .wrapper_title img {
        display: none;
    }

    .unique_items_page .wrapper_about_us_info .border_block {
        padding: 0;
        border: 0;
    }
}

@media (max-width: 992px) {
    .unique_items_page .wrapper_info_about_product {
        grid-template-columns: 1fr;
        grid-gap: 80px 0;
        padding: 28px 32px;
    }

    .unique_items_page .wrapper_info_about_product .wrapper_shipping_info {
        text-align: center;
    }
}

@media (max-width: 1650px) {
    .wrapper_info_about_item {
        grid-template-columns: 2fr auto;
    }
}

@media (max-width: 1250px) {

}

@media (max-width: 1050px) {
    .vertical_slider,
    .wrapper_info_about_item {
        grid-template-columns: 1fr;
    }

    .wrapper_select_input .wrapper_preview_category > span {
        width: auto;
    }

    .vertical_slider .slick-slider:first-child {
        margin-top: 0;
    }

    .vertical_slider {
        display: none;
    }

    .vertical_slider > .second_slider {
        display: none;
    }

    .info_about_item {
        grid-row: 2;
    }

    .horizontal_slider {
        display: block;
        width: 100%;
        height: 460px;
    }

    .horizontal_slider .slick-slider {
        position: absolute;
        height: 440px;
        left: 0;
        right: 0;
        width: 100%;
    }

    .horizontal_slider .slick-prev {
        left: 25px;
    }

    .horizontal_slider .slick-next {
        right: 0;
    }


    .vertical_slider .slick-slide img,
    .horizontal_slider .slick-slide img {
        height: 420px;
        width: 100%;
        object-fit: contain;
    }

    .vertical_slider .slick-slider:first-child div img {
        height: 100%;
    }

    .horizontal_slider .slick-dots li button:before,
    .horizontal_slider .slick-dots .slick-dots li button:before {
        color: #808080;
        content: '';
        width: 22px;
        height: 4px;
        background: #808080;
    }

    .horizontal_slider .slick-dots li.slick-active button:before,
    .horizontal_slider .slick-dots .slick-dots li.slick-active button:before {
        color: #C69C6D;
        opacity: 1;
        content: '';
        width: 22px;
        height: 4px;
        background: #C69C6D !important;
    }
}

@media (max-width: 600px) {
    .unique_items_page .wrapper_info_about_product {
        grid-gap: 52px 0;
    }

    .unique_items_page .wrapper_info_about_product .wrapper_title .main_title {
        font-size: 24px;
        line-height: 120%;
    }

    .unique_items_page .wrapper_info_about_product .wrapper_description {
        margin-top: 24px;
    }
}

@media (max-width: 450px) {
    .slick-slide img {
        height: 280px;
        object-fit: contain;
    }

    /*.wrapper_main_page {*/
    /*    margin-top: 60px;*/
    /*}*/

    .horizontal_slider .slick-slider {
        height: 312px;
    }

    .horizontal_slider .slick-slide img {
        height: 312px;
    }

    .horizontal_slider {
        height: 320px;
    }

    .horizontal_slider .slick-prev,
    .horizontal_slider .slick-next {
        display: none !important;
    }
}
