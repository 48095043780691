.reviews_page_wrapper h2 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #060606;
    margin-bottom: 1.5rem;
}

.reviews_page_wrapper h3 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 179%;
    color: #000000;
    margin-bottom: 2.25rem;
}

.reviews_page_wrapper {
    background: #FFFFFF;
    border-radius: 5px;
    width: 100%;
    padding: 4rem 5.5rem;
}

.reviews_page_wrapper_card_container {
    display: grid;
    grid-gap: 1.5rem 1rem;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.reviews_page_wrapper_card {
    border: 1px solid #CECECE;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    padding: 1rem;
}

.reviews_page_wrapper_card_content {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 1rem;
    margin-bottom: 4px;
}

.reviews_page_wrapper_card img {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
}

.reviews_page_wrapper_card span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 179%;
    color: #666666;
}

.reviews_page_wrapper_card p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 179%;
    color: #060606;
    margin: 0;
}

.reviews_page_wrapper_card small {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 179%;
    color: #C4C4C4;
}

.reviews_page_wrapper_card a {
    text-decoration: underline;
    color: #060606;
}

.reviews_page_wrapper_card_content:last-of-type {
    margin-bottom: 12px;
}