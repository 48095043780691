.slick_slide div .wrapper_every_goods {
    padding: 0 5px;
}

.wrapper_preview_section {
    margin: 80px 0;
    display: grid;
    grid-template-columns: 260px auto;
    padding-left: 40px;
}

.wrapper_landing_banner {
    margin: 0;
    grid-template-columns: 360px auto;
}

.wrapper_banners .wrapper_preview_section {
    grid-template-columns: 196px auto;
    grid-gap: 8px;
    margin: 16px 0;
}

.wrapper_banners {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
}

/*all categories*/
.wrapper_all_categories_landing {
    margin-bottom: 140px;
}

.wrapper_all_categories_landing .title_preview_category_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
}

.wrapper_all_categories_landing .title_preview_category_wrapper span {
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #333
}

.wrapper_all_categories_landing .title_preview_category_wrapper .link_to_all_categories {
    justify-self: end;
    align-self: center;
}

.wrapper_all_categories_landing .title_preview_category_wrapper a {
    padding: 10px 20px;
    font-size: 14px;
    line-height: 19px;
}

.wrapper_all_categories_landing .wrapper_all_categories_block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section .wrapper_preview_info {
    padding: 32px 0;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section .wrapper_submit_request .link {
    font-size: 14px;
    line-height: 100%;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section {
    margin: 0;
    padding-left: 24px;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section .preview_category_link {
    margin: 0;
    padding: 0;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section .wrapper_preview_info .wrapper_title {
    font-size: 32px;
    line-height: 100%;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section .preview_category_link a {
    padding: 0;
    border: none;
    border-bottom: 1px solid #C69C6D;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section .preview_category_link a:hover {
    background: transparent;
    color: #C69C6D;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(1) {
    grid-row: 1;
    grid-column: 1/3;
    grid-template-columns: 200px auto;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(2) {
    grid-row: 1;
    grid-column: 3;
    grid-template-columns: 1fr;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(3) {
    grid-row: 1/3;
    grid-column: 4;
    grid-template-columns: 1fr;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(5) {
    grid-row: 2;
    grid-column: 2/4;
    grid-template-columns: 200px auto;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(4) {
    grid-row: 2;
    position: relative;
    grid-template-columns: 1fr 50px;
    grid-column: 1;
}

.wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(4) .wrapper_preview_image img {
    position: absolute;
    top: 0;
    object-position: right;
    pointer-events: none;
    right: 0;
}


/*all categories*/

.wrapper_brands_slider {
    position: relative;
    display: block;
    height: inherit;
}

.horizontal_slider .slick-next,
.horizontal_slider .slick-prev,
.wrapper_brands_slider .slick-next,
.wrapper_brands_slider .slick-prev {
    background: #C69C6D;
    z-index: 10;
    width: 32px;
    height: 52px;
}

.horizontal_slider .slick-disabled,
.wrapper_brands_slider .slick-disabled {
    background: #C4C4C4;
}

.horizontal_slider .slick-next:before,
.wrapper_brands_slider .slick-next:before {
    content: url('../../assets/slider_vector_next.svg');
}

.slick-slider .slick-dots {
    display: flex !important;
    justify-content: flex-end;
}

.horizontal_slider .slick-prev:before,
.wrapper_brands_slider .slick-prev:before {
    content: url('../../assets/slider_vector_prev.svg');
}

.horizontal_slider .slick-prev:before,
.horizontal_slider .slick-next:before,
.wrapper_brands_slider .slick-prev:before,
.wrapper_brands_slider .slick-next:before {
    opacity: 1;
    font-size: 16px;
}

/*brands slider*/

.wrapper_brands_slider .slick-dots {
    text-align: right;
}

.wrapper_brands_slider .slick-dots li button:before,
.wrapper_brands_slider .slick-dots .slick-dots li button:before {
    color: #808080;
    content: '';
    width: 12px;
    height: 4px;
    background: #808080;
}

.wrapper_brands_slider .slick-dots li.slick-active button:before,
.wrapper_brands_slider .slick-dots .slick-dots li.slick-active button:before {
    color: #C69C6D;
    opacity: 1;
    content: '';
    width: 12px;
    height: 4px;
    background: #C69C6D;
}

/*brands slider*/


.wrapper_preview_section_light {
    background: #F9F9F9;
}

.wrapper_preview_section_dark {
    background: #333;
}

.wrapper_preview_category_section .preview_category_link,
.wrapper_preview_section .preview_category_link {
    margin-top: 20px;
    padding: 16px 0;
}

.wrapper_preview_category_section .preview_category_link a,
.wrapper_preview_section .preview_category_link a {
    /*padding: 16px 20px;*/
}

.wrapper_banners .preview_category_link a {
    font-size: 14px;
}

.wrapper_preview_section .wrapper_preview_info {
    display: grid;
    padding: 60px 0;
    height: max-content;
    grid-gap: 12px;
}


.wrapper_preview_section .wrapper_preview_info .wrapper_title {
    font-size: 40px;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #333
}

.wrapper_preview_section .wrapper_preview_info .wrapper_desc {
    font-size: 16px;
    line-height: 22px;
    color: #333
}

.wrapper_preview_section_dark .wrapper_preview_info .wrapper_desc,
.wrapper_preview_section_dark .wrapper_preview_info .wrapper_title {
    color: #fff;
}

.wrapper_preview_section .wrapper_preview_image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.wrapper_landing_banner .wrapper_preview_image {
    display: grid;
    align-content: center;
    justify-content: center;
}

.wrapper_landing_banner .wrapper_preview_image img {
    height: max-content;
}

.wrapper_banners .wrapper_preview_image img {
    object-position: bottom;
}

.wrapper_how_we_work {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin: 100px 0;
}

.wrapper_how_we_work .every_block {
    display: grid;
    grid-gap: 16px;
}

.wrapper_banners .wrapper_preview_info .wrapper_title {
    font-size: 30px;
    line-height: 100%;
}

.wrapper_how_we_work .every_block .wrapper_title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #000
}

.wrapper_how_we_work .every_block .wrapper_desc {
    font-size: 16px;
    line-height: 22px;
    color: #000
}

.wrapper_every_modal form .my-facebook-button-class {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
}

.wrapper_every_modal form button {
    /*margin-top: 16px;*/
    position: relative;
    width: 100%;
    padding: 8px !important;
}

.wrapper_every_modal form .my-facebook-button-class {
    padding: 18px !important;
    position: relative;
    cursor: pointer;
    width: 100%;
}

.wrapper_every_modal form button {
    display: flex !important;
    box-shadow: none !important;
    border: 1px solid #E9E9E9 !important;
    justify-content: center;
}

.wrapper_every_modal form .my-facebook-button-class {
    /*margin-top: 28px;*/
    background: #4267B2;
    color: #fff
}

.wrapper_every_modal form .continue_with_google {
    margin-top: 8px;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
}

.wrapper_every_modal form .my-facebook-button-class i {
    display: none;
}

.wrapper_every_modal form button div {
    position: absolute;
    left: 16px;
}

.wrapper_every_modal form .my-facebook-button-class:before {
    content: url("../../assets/continue_facebook.svg");
    position: absolute;
    left: 16px;
    margin-top: -3px;
}

.wrapper_every_modal form .break_wrapper .after,
.wrapper_every_modal form .break_wrapper .before {
    border: 1px solid #E9E9E9;
    align-self: center;
}

.wrapper_every_modal form .break_wrapper {
    /*padding: 26px 0;*/
    display: grid;
    grid-template-columns: 5fr 1fr 5fr;
    text-align: center;
    grid-gap: 35px;
}

.wrapper_every_modal form .break_wrapper span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #000;
}

.wrapper_brands_slider .wrapper_every_brand img{
    margin: 0 auto;
}

@media (max-width: 1150px) {
    .wrapper_brands_slider .slick-prev {
        left: 0;
    }
    .wrapper_landing_banner .wrapper_preview_image {
        display: grid;
        align-content: center;
        justify-content: center;
    }
    .wrapper_brands_slider .slick-next {
        right: 0;
    }
}

@media (max-width: 900px) {
    .wrapper_preview_section {
        grid-template-columns: 200px auto;
        padding-left: 80px;
    }

    .wrapper_banners {
        grid-template-columns: 1fr;
    }

    .wrapper_landing_banner {
        grid-template-columns: 300px auto;
    }

    .wrapper_preview_section .preview_category_link a {
        font-size: 14px;
    }


    /*all categories*/
    .wrapper_all_categories_landing {
        margin-bottom: 90px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section {
        max-height: 300px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(1) {
        grid-row: 1;
        grid-column: 1;
        grid-template-columns: 200px 1fr;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(2) {
        grid-row: 1;
        grid-column: 2;
        grid-template-columns: 200px 1fr;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(3) {
        grid-row: 2;
        grid-column: 2;
        grid-template-columns: 200px 1fr;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(5) {
        grid-row: 2;
        grid-column: 1;
        grid-template-columns: 200px 1fr;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(5) {
        grid-row: 3;
        grid-template-columns: 200px 1fr;
        grid-column: 1/3;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(4) .wrapper_preview_image img {
        position: absolute;
        top: 0;
        bottom: 0;
        object-position: right;
        pointer-events: none;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(5) .wrapper_preview_image img {
        height: 273px;
        object-fit: contain;
        object-position: 50% 68%;
    }

    /*all categories*/

}

@media (max-width: 768px) {
    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by > span {
        display: none;
    }

    .wrapper_preview_section {
        grid-template-columns: 1fr;
        padding-left: 0;
    }


    .wrapper_brands_slider .slick-prev,
    .wrapper_brands_slider .slick-next {
        display: none !important;
    }

    .wrapper_banners .wrapper_preview_section,
    .wrapper_how_we_work {
        grid-template-columns: 1fr;
    }

    .wrapper_how_we_work .every_block {
        display: grid;
        grid-gap: 0 12px;
        grid-template-columns: 50px 1fr;
    }

    .wrapper_how_we_work .every_block .wrapper_image {
        grid-row: 3/1;
        display: grid;
        align-content: start;
        grid-column: 1;
    }

    .wrapper_how_we_work .every_block .wrapper_desc {
        grid-row: 2;
        font-size: 16px;
        grid-column: 2;
    }

    .wrapper_preview_section .wrapper_preview_info {
        padding: 40px 0;
        text-align: center;
    }

    /*all categories*/
    /*all categories*/
    .wrapper_all_categories_landing {
        margin-bottom: 90px;
    }

    .wrapper_preview_section .wrapper_preview_image img {
        height: max-content;
        max-height: 300px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section {
        max-height: 300px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(1) {
        grid-row: 1;
        grid-column: 1/-1;
        grid-template-columns: 200px 1fr;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(2) {
        grid-row: 2;
        grid-column: 1/-1;
        grid-template-columns: 200px 1fr;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(3) {
        grid-row: 3;
        grid-column: 1/-1;
        grid-template-columns: 200px 1fr;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(5) {
        grid-row: 4;
        grid-column: 1/-1;
        grid-template-columns: 200px 1fr;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(4) {
        grid-row: 5;
        grid-column: 1/-1;
        grid-template-columns: 1fr 1fr;
        height: 230px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(4) .wrapper_preview_info {
        grid-column: 2;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(4) .wrapper_preview_image {
        grid-column: 1;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(4) .wrapper_preview_image img {
        position: absolute;
        left: 0;
        transform: rotateY(180deg);
        top: 0;
        object-position: right;
        right: unset;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(5) .wrapper_preview_image img {
        height: 273px;
        object-fit: contain;
        object-position: 50% 68%;
    }

    /*all categories*/
}

@media (max-width: 450px) {
    /*all categories*/
    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(1) {
        grid-row: 1;
        grid-column: 1/-1;
        grid-template-columns: 1fr;
    }



    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section {
        max-height: 427px;
        padding-left: 0;
    }

    .wrapper_all_categories_landing .title_preview_category_wrapper .link_to_all_categories {
        justify-self: start;
    }

    .wrapper_all_categories_landing .title_preview_category_wrapper {
        grid-template-columns: 1fr;
        grid-gap: 12px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(2) {
        grid-template-columns: 1fr 154px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(5),
    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(3) {
        grid-template-columns: 1fr;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(5) .wrapper_preview_image img,
    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(3) .wrapper_preview_image img {
        max-height: 245px;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(2) .wrapper_preview_image img {
        transform: rotateY(180deg);
        position: absolute;
        left: 0;
        top: 0;
        margin-left: -10px;
        object-position: 50% 68%;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(2) .wrapper_preview_image {
        grid-column: 1;
        grid-row: 1;
        position: relative;
    }

    .wrapper_all_categories_landing .wrapper_all_categories_block .wrapper_preview_section:nth-child(2) .wrapper_preview_info {
        grid-column: 2;
        grid-row: 1;
    }

    /*all categories*/
    .wrapper_preview_section .preview_category_link a {
        padding: 10px 12px;
    }

    .wrapper_brands_slider {
        position: relative;
        display: block;
        width: auto;
        margin: 0;
    }

    .wrapper_landing_banner .wrapper_preview_image img {
        object-fit: contain;
    }

    .wrapper_how_we_work .every_block .wrapper_title {
        font-size: 18px;
    }

    .wrapper_how_we_work .every_block .wrapper_desc {
        font-size: 14px;
    }

    .wrapper_preview_section .wrapper_preview_info .wrapper_title {
        font-size: 32px;
    }

    .wrapper_preview_section .wrapper_preview_info .wrapper_desc {
        font-size: 14px;
        padding: .75rem;
    }

    .wrapper_preview_section .preview_category_link {
        margin-top: 12px;
    }

    .wrapper_all_categories_block > div:first-child .wrapper_preview_image img {
        object-position: right;
        max-height: 260px;
    }
}
