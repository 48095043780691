* {
    box-sizing: border-box;
    transition: .3s all;
}

.open_sans_300 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

.open_sans_400 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.open_sans_600 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.open_sans_700 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.open_sans_800 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
}

.playfair_display_400 {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}

.playfair_display_500 {
    font-family: 'Playfair Display', serif;
    font-weight: 500;
}

.playfair_display_600 {
    font-family: 'Playfair Display', serif;
    font-weight: 600;
}

.playfair_display_700 {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
}

.playfair_display_800 {
    font-family: 'Playfair Display', serif;
    font-weight: 800;
}

.playfair_display_900 {
    font-family: 'Playfair Display', serif;
    font-weight: 900;
}

.roboto_400 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.roboto_500 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.roboto_700 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.roboto_900 {
    font-family: 'open-sans', sans-serif;
    font-weight: 900;
}

.wrapper_error_text {
    margin-bottom: 24px;
    color: #FF0E00;
    font-size: 14px;
}

body {
    margin: 0;
    padding: 0;
    font-style: normal;
    background: #ffffff;
}

button, textarea,
input, a,
input:focus,
button:focus,
textarea:focus {
    outline: none;
    /*border: none;*/
    text-decoration: none;
    cursor: pointer;
}

.wrapper_app {
    padding: 0 10%;
    overflow-y: hidden !important;
}

.wrapper_main_page {
    margin-top: 24px;
}

.wrapper_main_page .wrapper_content .wrapper_every_goods {
    padding: 0;
}

.active {
    color: red;
}

.wrapper_every_modal {
    position: fixed;
    z-index: 100000;
    background: rgba(0, 0, 0, 0.1);
    left: 0;
    display: grid;
    align-content: center;
    justify-content: center;
    right: 0;
    top: 0;
    bottom: 0;
}

.wrapper_every_modal .modal {
    background: #fff;
    padding: 60px 100px;
    min-width: 420px;
}

.wrapper_every_modal .modal .close_modal {
    display: none;
}

.wrapper_submit button {
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    line-height: 25px;
    color: #fff;
    background: #333;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 8px 42px;
}

.wrapper_submit_request {
    margin-top: 12px;
}

.wrapper_submit_request .link,
.wrapper_submit_request button {
    font-size: 16px;
    width: 195px;
    cursor: pointer;
    line-height: 22px;
    text-transform: uppercase;
    color: #C69C6D;
    border: 1px solid #C69C6D;
    padding: 20px 12px;
    box-sizing: border-box;
    background: transparent;
    transition: .4s;
    display: inline-block;
    text-align: center;
}

.wrapper_submit_request .link:hover,
.wrapper_submit_request button:hover {
    background: #C69C6D;
    color: #fff;
}

.wrapper_submit_request button path {
    transition: .4s all;
}

.wrapper_submit_request button:hover path {
    fill: #ffffff;
}

a {
    text-decoration: none;
}

.page_title {
    padding: 28px 0;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #333
}

.page_title_logout {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
}

.page_title_logout p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #C69C6D;
    cursor: pointer;
}

.input_error {
    border-color: #FF0E00;
    color: #FF0E00;
    border-width: 0 0 1px 0;
}

#text_error {
    color: #FF0E00;
}


.overflow_scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F9F9F9;
}

.overflow_scroll::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.overflow_scroll::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #C69C6D;
}

.wrapper_pagination {
    grid-column: 1/-1;
}

.wrapper_pagination .pagination {
    padding: 0;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 12px;
    justify-content: center;
}

.wrapper_pagination .pagination li {
    list-style: none;
    border: 1px solid #333333;
    cursor: pointer;
    box-sizing: border-box;
    padding: 4px 10px;
}

.wrapper_pagination .pagination li a {
    color: #333;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
}

.wrapper_pagination .next_page_pagination {
    transform: rotate(-89deg);
    height: 8px;
}

.wrapper_pagination .prev_page_pagination {
    transform: rotate(89deg);
    height: 8px;
}

.wrapper_pagination .pagination .active {
    background: #C69C6D;
}

.wrapper_pagination .pagination .active a {
    color: #fff
}

.wrapper_pagination .pagination .disabled {
    display: none;
}

.delete_mobili {
    display: block;
}

@media (max-width: 1250px) {
    .wrapper_app {
        padding: 0 20px;
    }

}

@media (max-width: 768px) {
    /*.wrapper_main_page {*/
    /*    margin-top: 99px;*/
    /*}*/

    .delete_mobili {
        display: none;
    }

    .wrapper_every_modal .modal {
        padding: 40px 60px;
        max-width: 320px;
        position: relative;
    }

    .wrapper_every_modal .modal .close_modal {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        height: 20px;
        width: 20px;
        cursor: pointer;
    }

    .wrapper_submit_request .link:hover,
    .wrapper_submit_request button:hover {
        background: #fff;
        color: #C69C6D;
    }
}

@media (max-width: 600px) {
    .page_title_logout {
        grid-template-columns: 1fr;
    }
}
@media (max-width: 480px) {
    /*.wrapper_main_page {*/
    /*    margin-top: 96px;*/
    /*}*/



    .wrapper_every_modal .modal {
        background: #fff;
        padding: 40px;
        min-width: 340px;
    }

    .page_title {
        padding: 16px 0;
        font-size: 24px;
        line-height: 32px;
    }

    .wrapper_submit_request button {
        font-size: 14px;
        padding: 12px 0;
    }
    .page_title_logout {
        grid-template-columns: max-content;
    }
}
