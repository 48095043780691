.button_default {
    background: #333333;
    border-radius: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;
    box-sizing: border-box;
    display: inline-block;
    padding: unset;
    border: unset;
    transition: .4s all;
    font-family: 'Open Sans', sans-serif;
    /*border: 2px solid #0075FF;*/
}

.button_default img {
    margin-right: 8px;
    margin-bottom: 2px;
}

/*.main_button:hover {*/
/*    background: #66ACFF;*/
/*    border: 2px solid #66ACFF;*/
/*}*/

/*.main_button:active {*/
/*    background: #005ECC;*/
/*    border: 2px solid #005ECC;*/
/*}*/

.main_button:disabled {
    background: #E3E4EB;
    color: #8e90a6;
    /*border: 2px solid #E3E4EB;*/
    cursor: auto;
}

.button_accent {
    background: #0CCC92 !important;
}

.button_accent:focus {
    background: #0CCC92 !important;
    border: 1px solid #088072 !important;
}

.button_accent:hover {
    background: #07BE87 !important;
}

.button_accent:active {
    background: #0CCC92 !important;
    border: none !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.button_accent:disabled {
    background: #B7DDD2 !important;
    color: #3EBDC0 !important;
}

.warning_button {
    background: #FFAB00 !important;
}

.warning_button:focus {
    background: #FFAB00 !important;
    border: 1px solid #D99100 !important;
}

.warning_button:hover {
    background: #D99100 !important;
}

.warning_button:active {
    border: none !important;
    background: #FFAB00 !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.warning_button:disabled {
    background: #F8DFAD !important;
    color: #C7AF7F !important;
}

.danger_button {
    background: #FE3E3E !important;
}

.danger_button:focus {
    background: #FE3E3E !important;
    border: 1px solid #CC0505 !important;
}

.danger_button:hover {
    background: #CC0505 !important;
}

.danger_button:active {
    background: #FE3E3E !important;
    border: none !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.danger_button:disabled {
    background: #FFC5C5 !important;
    color: #DC7F7F !important;
}

.cancel_button {
    background: transparent;
    color: #2E3A59;
    cursor: pointer;
    border: none;
}

.cancel_button:focus {
    color: #2E3A59;
}

.cancel_button:hover {
    background: transparent;
}

.cancel_button:active {
    border: none;
    background: transparent;
}

.cancel_button:disabled {
    background: white;
    border: 1px solid #5C9BFF;
    color: #2E3A59;
}

.download_button {
    background: #FFFFFF !important;
    color: #5C9BFF !important;
}

.download_button:focus {
    background: #EAF6FD !important;
    border: 1px solid #8ED6FF !important;
    color: #2F80ED !important;
}

.download_button:hover {
    background: #EAF6FD !important;
}

.download_button:active {
    border: none !important;
    background: #FFFFFF !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.download_button:disabled {
    background: white !important;
    color: #BCBCBC !important;
}

.delete_button {
    background: #FFFFFF !important;
    color: #FE3E3E !important;
}

.delete_button:focus {
    background: rgba(254, 62, 62, 0.1) !important;
    border: 1px solid #FE3E3E !important;
}

.delete_button:hover {
    background: rgba(254, 62, 62, 0.1) !important;
}

.delete_button:active {
    background: rgba(254, 62, 62, 0.1) !important;
    border: none !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.delete_button:disabled {
    background: white !important;
    color: #BCBCBC !important;
}

.add_button {
    background: #0CCC92 !important;
}

.add_button:focus {
    background: #0CCC92 !important;
    border: 1px solid #088072 !important;
}

.add_button:hover {
    background: #34B08B !important;
}

.add_button:active {
    background: #0CCC92 !important;
    border: none !important;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.add_button:disabled {
    border: 1px solid #088072 !important;
}

.main_border {
    border: 1px solid #C7C8D9;
    background: transparent;
    color: #0075FF;
}

.main_border:hover {
    background: #FAFAFC;
    border: 1px solid #EBEBF0;
    color: #0075FF;
}

.main_border:active {
    background: #FAFAFC;
    border: 1px solid #005ECC;
    color: #005ECC;
}

.main_border:disabled {
    background: transparent;
    border: 1px solid #E3E4EB;
    color: #8E90A6;
}

.main_border:focus {
    background: #FAFAFC;
    border: 1px solid #66ACFF;
    color: #66ACFF;
}

@media (max-width: 568px) {
    .main_button {
        width: 100%;
    }

}