@import "variables";

.sales {
    margin-left: 255px;
    background-color: $color-white-light;
    min-height: 100vh;

    &__wrapper {
        padding: 32px 44px 68px;
        background-color: $color-white;
    }

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__item {
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 24px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba($color: $color-black, $alpha: 0.1);
    }

    & .g-save {
        margin-top: 16px;
        align-self: flex-end;
    }
}