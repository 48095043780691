.show_message{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(52,38,38, 0.2);
    display: grid;
    justify-content: center;
    align-content: center;
}

.show_message .message {
    padding: 32px;
    background-color: white;
    height: fit-content;
    width: 768px;
}

.show_message .message_header {
    padding-bottom: 24px;
    display: grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px #CECECE solid;
}

.show_message .message_header span{
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
}

.show_message .message_text span{
    font-size: 16px;
    line-height: 22px;
}

.show_message .message_text{
    padding-top: 16px;
}
