.unique_goods_order_popup {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
}

.unique_goods_order_popup_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    padding: 3rem;
    width: 500px;
}

.unique_goods_order_popup_content .close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    cursor: pointer;
}

.unique_goods_order_popup_content h2 {
    font-family: 'Playfair Display', sans-serif;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #000000;
    margin: 0 0 20px;
}

.unique_goods_order_popup_content p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 0 0 28px;
}

.unique_goods_order_popup_content button {
    border: 1px solid #C69C6D;
    width: 100%;
    display: block;
    padding: 19px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #C69C6D;
    background: transparent;
}

.unique_goods_order_popup_content_admin_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    align-items: center;
}

.unique_goods_order_popup_content_admin_buttons button {
    border: 1px solid #000000;
    color: #000000;
}

@media (max-width: 568px) {
    .unique_goods_order_popup_content {
        padding: 1rem;
        width: calc(100% - 1rem);
    }
}