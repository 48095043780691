.delivery_address_modal_confirmed {
    text-align: center;
    padding: 1rem 1rem .5rem;
}

.delivery_address_modal_confirmed h4 {
    font-family: 'Playfair Display', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333333;
    margin: 2rem 0 1rem;
}

.delivery_address_modal_confirmed p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 3rem;
}

.delivery_address_modal_confirmed a {
    display: inline-block;
    width: max-content;
    width: -moz-max-content;
    margin: 0 auto;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #333333;
    padding: 1rem 3.75rem;
}