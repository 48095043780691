.wrapper_auth_variant {
    display: grid;
    grid-gap: 0 12px;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    padding: 20px 0;
}

.wrapper_auth_variant span {
    align-self: center;
    letter-spacing: 0.02em;
    transition: color .4s;
    font-size: 36px;
    line-height: 48px;
}

.wrapper_auth_variant .active_auth_variant {
    color: #333;
}

.wrapper_auth_variant .un_active_auth_variant {
    cursor: pointer;
    color: rgba(51, 51, 51, .61);
}

.wrapper_every_modal .modal .wrapper_submit {
    display: grid;
    grid-template-columns: max-content max-content;
}

.wrapper_every_modal .modal .wrapper_auth_btn {
    display: grid;
    grid-gap: 16px 0;
}

.wrapper_every_modal .modal .wrapper_submit .forgot_password {
    align-self: center;
    margin-left: 32px;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #333
}

.wrapper_every_modal .modal .wrapper_description {
    font-size: 24px;
    padding: 20px 0 40px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #333
}

.wrapper_every_modal .modal .wrapper_every_inputs {
    margin: 28px 0;
    display: grid;
    grid-gap: 16px 0;
}

@media (max-width: 768px) {
    .wrapper_auth_variant span {
        font-size: 24px;
        line-height: 32px;
    }

    .wrapper_every_modal .modal .wrapper_description {
        padding: 12px;
        margin-bottom: 12px;
        font-size: 22px;
    }

    .wrapper_every_modal .modal .wrapper_every_inputs {
        margin: 12px 0;
        grid-gap: 12px 0;
    }
}

@media (max-width: 480px) {
    .wrapper_auth_variant {
        grid-template-columns: 1fr;
    }

    .wrapper_auth_variant span {
        font-size: 20px;
        line-height: 28px;
    }

    .wrapper_every_modal .modal .wrapper_submit {
        grid-template-columns: 1fr;
        grid-gap: 20px 0;
    }

    .wrapper_every_modal .modal .wrapper_submit .forgot_password {
        margin: 0;
    }

    .wrapper_every_modal .modal .wrapper_description {
        padding: 0;
        font-size: 18px;
    }

    .wrapper_every_modal .modal .wrapper_every_inputs {
        margin: 12px 0 24px;
        grid-gap: 12px 0;
    }

    .break_wrapper {
        /*padding: 26px 0;*/
        display: grid;
        grid-template-columns: 5fr 1fr 5fr;
        text-align: center;
        grid-gap: 35px;
        margin-right: -30px;
        margin-left: -30px;
    }

    .wrapper_every_modal .wrapper_submit button {
        font-weight: 400;
        font-size: 14px;
        padding: 8px 20px;
    }
}
