header {
    position: relative;
    background: #F9F9F9;
    display: grid;
    z-index: 100;
    grid-template-columns: 278px 1fr;
    grid-template-rows: 1fr max-content;
}


header .wrapper_logo {
    grid-row: 1/-1;
    background: #333;
    padding: 23px;
    display: grid
}

header .wrapper_burger_navigation_active,
header .header_navigation .wrapper_burger_menu {
    display: none;
}

header .wrapper_logo .logo {
    font-size: 31px;
    line-height: 41px;
    color: #C69C6D;
}

header .wrapper_header_navigation .wrapper_info_in_header {
    display: grid;
    justify-self: end;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 30px;
    padding: 0 12px;
}

header .wrapper_header_navigation .wrapper_info_in_header a {
    align-self: center;
    text-decoration: none;
    padding: 12px 0;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
}

header .wrapper_sign_popup {
    padding: 0 12px;
    width: max-content;
    margin: 12px 0;
    border-left: 1px solid #E0E0E0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
}

.header-lang-profile .request-design {
    margin: 12px 16px 12px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
    cursor: pointer;
}

.header-lang-profile .request-design:hover {
    color: #C69C6D;
}


.header-mobile-services button,
.other-services-button-wrapper button,
.wrapper_links .request-design-button {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #C69C6D;
    padding: 10px 32px;
    border: 1px solid #C69C6D;
    background-color: transparent;
    transition: .3s;
}

.header-mobile-services button:hover,
.other-services-button-wrapper button:hover,
.wrapper_links .request-design-button:hover {
    background-color: #C69C6D;
    color: white;
}

.header-other-services {
    background: #333333;
    padding: 18px 50px 32px;
}

.header-mobile-services span,
.header-other-services span {
    display: block;
    width: max-content;
    padding-top: 18px;
    border-top: 1px solid #C69C6D;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #F9F9F9;
}

.other-services-button-wrapper {
    background: #383838;
}


.other-services-button-wrapper button {
    padding: 10px;
    margin-top: 28px;
}

.header-mobile-services {
    display: flex;
    flex-direction: column;
    grid-column: 1/-1;
}

.header-mobile-services span {
    margin-top: 12px;
    padding-top: 12px;
    color: #333;
    width: 100%;
}

.header-mobile-services button {
    margin-top: 12px;
}

.lang-change {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-left: 1px solid #e0e0e0;
    padding-left: 16px;
    margin: 12px 16px 12px 0;
    height: max-content;
}

.lang-change > * + * {
    margin-left: 5px;
}

header .wrapper_sign_popup span:last-child,
header .wrapper_sign_popup span:first-child {
    cursor: pointer;
}

header .wrapper_sign_popup .header_user_name {
    font-size: 16px;
    border: none;
}

header .wrapper_sign_popup img {
    margin: 4px 0 0 4px;
}

header .wrapper_header_navigation {
    grid-row: 1/-1;
    position: relative;
}

header .wrapper_header_navigation .header_navigation {
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0 12px 12px 0;
    grid-gap: 0 8px;
}

header .search_and_cart_wrapper {
    justify-self: end;
    padding: 12px;
    align-content: start;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
}

header .search_and_cart_wrapper_active {
    grid-template-columns: repeat(2, 1fr);
}

header .search_and_cart_wrapper .wrapper_cart {
    position: relative;
}

header .search_and_cart_wrapper .wrapper_cart .cart_length {
    position: absolute;
    background: #fff;
    display: grid;
    align-content: center;
    justify-content: center;
    border: 1px solid #333;
    font-size: 12px;
    top: -10px;
    border-radius: 50%;
    z-index: 10;
    right: -10px;
    color: #C69C6D;
    width: 22px;
    height: 22px;
}

.header-lang-profile {
    display: flex;
}

.header-lang-profile,
header .wrapper_header_navigation .wrapper_info_in_header .wrapper_sign_popup {
    grid-column-start: none;
    justify-self: end;
}

header .search_and_cart_wrapper > div {
    align-self: center;
    cursor: pointer;
    justify-self: center;
}

header .wrapper_header_navigation .header_navigation .navigation {
    display: flex;
    flex-flow: wrap;
    align-content: center;
}

header .navigation .every_navigation_links {
    font-size: 16px;
    padding-left: 12px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #333;
    transition: .4s;
}

header .navigation .active_nav_link {
    color: #AF8B63;
}

header .navigation .every_navigation_links:hover {
    color: #C69C6D;
}

header .search_input,
header .wrapper_search_input {
    width: 0;
    height: 0;
    padding: 0;
    transition: .4s;
    opacity: 0;
}

header .search_input_un_active {
    transition: unset;
}

header .input_icon_search_active {
    position: absolute;
    left: 12px;
    margin-top: 0;
    right: 200px;
}


header .input_icon_search_active img {
    position: absolute;
    top: 0;
}

header .input_icon_search .vector_close_search_input {
    display: none;
}

header .input_icon_search_active .vector_close_search_input_active {
    position: absolute;
    display: block;
    right: -73px;
    margin-top: 4px;
}

header .input_icon_search .wrapper_search_input {
    opacity: 0;
}

header .input_icon_search_active .search_input {
    display: block;
    width: 100%;
    margin-top: -12px;
    height: auto;
    padding: 12px 40px;
    opacity: 1;
}

header .input_icon_search_active .wrapper_search_list {
    background: #fff;
    width: 100%;
    max-height: 288px;
    padding: 16px;
    z-index: 100;
    display: grid;
    grid-gap: 16px 0;
    margin-top: 20px;
    overflow-y: scroll;
}

header .input_icon_search_active .wrapper_search_list .wrapper_every_items {
    border-width: 0 0 1px 0;
    border-color: #F9F9F9;
    border-style: solid;
    display: grid;
    grid-gap: 0 16px;
    grid-template-columns: max-content 1fr max-content;
}

header .input_icon_search_active .wrapper_search_list .wrapper_every_items .wrapper_info .wrapper_item_title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333
}

header .input_icon_search_active .wrapper_search_list .wrapper_every_items .wrapper_info .wrapper_item_desc {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #828282;
}

header .input_icon_search_active .wrapper_search_list .wrapper_every_items:last-child {
    border: none;
}

header .input_icon_search_active .wrapper_search_list .wrapper_every_items .wrapper_item_image {
    height: 40px;
    width: 40px;
    position: relative;
    display: grid;
    align-self: center;
}

header .input_icon_search_active .wrapper_search_list .wrapper_every_items .wrapper_item_image img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

@media (max-width: 1200px) {
    header {
        grid-template-columns: 240px 1fr;
    }

    header .wrapper_header_navigation .wrapper_info_in_header {
        justify-self: unset;
        grid-gap: 12px;
        margin-left: 12px;
        padding: 0;
    }

    header .wrapper_header_navigation .wrapper_info_in_header a {
        justify-self: start;
    }

    header .wrapper_header_navigation .wrapper_info_in_header .wrapper_sign_popup {
        grid-column-start: none;
        justify-self: end;
        display: flex;
    }

    header .wrapper_header_navigation .header_navigation .navigation .every_navigation_links {
        padding-left: 8px;
    }

    header .wrapper_header_navigation .header_navigation {
        padding: 8px 8px 8px 0;
    }

}

@media (max-width: 1250px) {
    header .wrapper_header_navigation .wrapper_info_in_header .wrapper_sign_popup {
        margin-left: -5px;
    }

    header {
        grid-template-columns: 240px 1fr;
    }

    header .wrapper_header_navigation .wrapper_info_in_header .wrapper_sign_popup {
        grid-column-start: none;
        margin: 12px 0;
    }

    header .wrapper_logo .logo {
        font-size: 28px;
    }
}

@media screen and (max-width: 768px) {
    header {
        grid-template-rows: 1fr;
        grid-template-columns: 240px 1fr;
    }

    header .search_and_cart_wrapper {
        padding: 4px;
    }

    /*burger start*/
    header .header_navigation .wrapper_burger_menu {
        display: grid;
        cursor: pointer;
        width: max-content;
        padding: 10px 18px;
        position: relative;
        height: 49px;
        justify-self: end;
    }

    header .header_navigation .wrapper_burger_menu .burger_menu,
    header .header_navigation .wrapper_burger_menu .burger_menu:after,
    header .header_navigation .wrapper_burger_menu .burger_menu:before {
        position: absolute;
        content: '';
        transition: .4s;
        width: 36px;
        height: 4px;
        align-self: center;
        background: #0F0F10;
        border-radius: 6px;
    }

    header .header_navigation .wrapper_burger_menu .burger_menu:before {
        margin-top: -10px;
    }

    header .header_navigation .wrapper_burger_menu .burger_menu:after {
        margin-top: 10px;
    }

    header .header_navigation .wrapper_burger_menu .burger_menu_active {
        transform: rotate(-45deg);
    }

    header .header_navigation .wrapper_burger_menu .burger_menu_active:before {
        transform: rotate(90deg);
        margin-top: 0;
    }

    header .wrapper_burger_navigation_un_active,
    header .wrapper_burger_navigation_active {
        position: fixed;
        display: block;
        background: #fff;
        top: 87px;
        z-index: 100;
        bottom: 0;
        left: 0;
        padding: 0 20px;
        right: 0;
        transition: .4s;
    }

    header .wrapper_burger_navigation_un_active {
        left: 150vw;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll {
        overflow: auto;
        height: calc(100vh - 68px);
        position: relative;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search {
        grid-column: 1/-1;
        position: relative;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search img {
        position: absolute;
        margin-top: 14px;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .favorite {
        display: none;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .wrapper_search_input {
        opacity: 1;
        height: 50px;
        width: 100%;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .search_input {
        padding: 8px 40px;
        opacity: 1;
        height: 100%;
        width: 100%;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .wrapper_search_list {
        background: #fafafa;
        width: 100%;
        max-height: 391px;
        padding: 16px;
        z-index: 100;
        display: grid;
        grid-gap: 16px 0;
        margin-top: 8px;
        overflow-y: scroll;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .wrapper_search_list .wrapper_every_items {
        border-width: 0 0 1px 0;
        border-color: #F9F9F9;
        border-style: solid;
        display: grid;
        grid-gap: 0 16px;
        background: #fff;
        grid-template-columns: max-content 1fr max-content;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .wrapper_search_list .wrapper_every_items .wrapper_info .wrapper_item_desc {
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #828282;
    }

    .every_subcategory_menu a:hover {
        color: unset !important;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .wrapper_search_list .wrapper_every_items:last-child {
        border: none;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .wrapper_search_list .wrapper_every_items .wrapper_item_image {
        height: 40px;
        width: 40px;
        position: relative;
        display: grid;
        align-self: center;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .wrapper_search_list .wrapper_every_items .wrapper_item_image img {
        object-fit: contain;
        margin: 0;
        width: 100%;
        height: 100%;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .wrapper_search_list .wrapper_every_items .wrapper_info .wrapper_item_title {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: #333
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_input_search .wrapper_search_list .wrapper_every_items .wrapper_info .wrapper_item_desc {
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #828282;
    }


    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .navigation {
        display: grid;
        margin-top: 32px;
        grid-gap: 12px;
        grid-column: 1/-1;
    }

    header .navigation .every_navigation_links {
        font-size: 20px;
        width: max-content;
        line-height: 28px;
        letter-spacing: 0.02em;
        padding: 0;
        text-transform: uppercase;
        color: #333
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .search_and_cart_wrapper {
        display: none;
    }

    .header-lang-profile {
        justify-self: start;
        grid-column: 1/-1;
    }

    .header-lang-profile,
    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_sign_popup {
        border: none;
        padding: 0;
        font-size: 18px;
        line-height: 25px;
        color: #333;
    }

    header .search_and_cart_wrapper {
        position: absolute;
        right: 30px;
    }

    header .search_and_cart_wrapper .input_icon_search {
        display: none;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_mobili_connection {
        display: grid;
        grid-gap: 24px;
        margin-top: 31px;
        grid-column: 1/-1;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .wrapper_mobili_connection a {
        font-size: 18px;
        line-height: 21px;
        width: max-content;
        color: #828282;
    }

    /*burger end*/
    header .wrapper_logo {
        padding: 12px;
    }

    header .wrapper_header_navigation .header_navigation {
        padding: 8px;
        margin: 0;
        grid-template-columns: 1fr max-content;
    }

    header .header_navigation .wrapper_burger_menu .burger_menu_active:after,
    header .wrapper_header_navigation .header_navigation .navigation,
    header .wrapper_header_navigation .wrapper_info_in_header {
        display: none;
    }

    header .search_and_cart_wrapper .wrapper_cart {
        top: 10px;
        padding-left: 3px;
    }

}

@media screen and (max-width: 480px) {
    header {
        grid-template-columns: 205px 1fr;
    }

    header .search_and_cart_wrapper {
        right: 0;
    }

    header .wrapper_burger_navigation_un_active,
    header .wrapper_burger_navigation_active {
        top: 90px;
    }

    header .search_and_cart_wrapper,
    header .wrapper_header_navigation .header_navigation .search_and_cart_wrapper {
        display: none;
    }

    header .wrapper_burger_navigation_active .burger_navigation_scroll .header_navigation .search_and_cart_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 5px;
    }

    header .wrapper_logo > div {
        align-self: center !important;
    }

    header .wrapper_logo .logo {
        font-size: 24px;
        line-height: 32px;
    }

    header .header_navigation .wrapper_burger_menu {
        height: 49px;
        justify-self: end;
    }

    header .wrapper_header_navigation .header_navigation {
        justify-self: end;
        grid-template-columns: none;
    }
}


.subcategory_popup {
    display: none;
    grid-column: 1/-1;
    position: relative;
    overflow-y: scroll;
    grid-template-columns: 1fr;
    /*padding: 50px 50px 10px 50px;*/
}

.subcategory_brands_active_popup {
    display: grid;
    height: max-content;
    padding: 32px 32px 32px 0;
    grid-template-columns: 120px 120px;
    color: white;
    grid-column: 1/-1;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 40px;
}
.subcategory_brands_active_popup a{
    align-self: center;
    justify-self: center;
}

/*header .subcategory_popup {*/
header:hover .subcategory_popup {
    display: grid;
    overflow-y: auto;
    grid-template-columns: 1fr max-content;
    grid-gap: 22px;

    background: #383838;
}

header .wrapper_header_navigation:hover + .subcategory_popup {
    display: none;
}

.detectHoverClick:hover .subcategory_popup {
    display: none;
}

.every_subcategory_menu {
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 22px;
    display: grid;
    height: max-content;
    grid-gap: 0 20px;
    grid-template-columns: 278px 2fr;
}

.every_subcategory_menu__category_name {
    display: grid;
    grid-gap: 20px 0;
    padding: 40px 50px;
    background-color: #333;
}

.every_subcategory_menu__category_name span {
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    line-height: 24px;
    color: #fff;
}

.every_subcategory_menu__subcategory_name {
    display: grid;
    padding: 40px 0;
    grid-template-columns: 1fr 1fr;
    height: max-content;
    grid-gap: 20px;
}

.every_subcategory_menu__subcategory_name .every_subcategory_brands {
    display: none;
}

.every_subcategory_menu__category_name .active {
    color: #C69C6D;
}

.every_subcategory_categories {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-size: 18px;
    line-height: 40px;
    display: grid;
    grid-template-columns: 1fr;
}

.every_subcategory_brands {
    display: grid;
    height: max-content;
    padding: 32px 32px 32px 0;
    grid-template-columns: 1fr;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 40px;
}

.every_subcategory_menu a {
    color: white;
    width: fit-content;

    height: auto;
    word-break: break-word !important;
}

.every_subcategory_menu a:hover {
    color: #AF8B63;
}

.every_subcategory_categories a {
    color: white;
    width: 230px;
    height: 30px;
}

.every_subcategory_categories a:hover {
    color: #AF8B63;
}

.every_subcategory_brands a {
    color: white;
    width: 230px;
    height: 30px;
}

.every_subcategory_brands .brand_title {
    grid-column: 1/-1;
    text-align: center;
}

/*.every_subcategory_brands {*/
/*    grid-template-columns: repeat(3, 1fr);*/

/*}*/

.every_subcategory_brands .photo_brands {
    height: 50px;
    width: auto;
}

.every_subcategory_brands .photo_brands img {
    width: 106px;
    object-fit: none;
    height: 50px;
}

.every_subcategory_brands a:hover {
    color: #AF8B63;
}

.brand_title {
    font-weight: 400;
    font-size: 24px;
}

.image, .image_small {
    display: none;
}


@media (max-width: 1400px) {
    .subcategory_popup {
        overflow-y: auto;
        grid-template-columns: 1fr;
    }

    .every_subcategory_menu {
        grid-template-columns: 240px 1fr;
    }

    .subcategory_popup::-webkit-scrollbar-track {
        background-color: #333;
    }

    .subcategory_popup::-webkit-scrollbar {
        width: 5px;
        background-color: #333;
    }

    .subcategory_popup::-webkit-scrollbar-thumb {
        background-color: #AF8B63;
    }
}

@media (max-width: 800px) {

    .subcategory_popup > .every_subcategory_brands {
        display: none;
    }

    .subcategory_popup .every_subcategory_menu__subcategory_name .every_subcategory_brands {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0;
        grid-column: 1/-1;
    }

    .subcategory_popup .every_subcategory_menu__subcategory_name .every_subcategory_brands {
        display: none;
    }

    .subcategory_popup .every_subcategory_brands {
        grid-template-columns: repeat(3, 1fr);
        height: max-content;
    }


    .image {
        display: block;
        position: absolute;
        width: 30px;
        right: 12px;
        cursor: pointer;
        top: 12px;
    }
}

@media (max-width: 768px) {
    .header-lang-profile .request-design {
        display: none;
    }

    .lang-change {
        padding-left: 0;
        border-left: none;
    }
}

@media (max-width: 650px) {
    /*.subcategory_popup .every_subcategory_menu__subcategory_name > span {*/
    /*    display: none;*/
    /*}*/
    /*.every_subcategory_menu__category_name .active {*/
    /*    color: unset;*/
    /*}*/
    /*.subcategory_popup .every_subcategory_menu__subcategory_name .every_subcategory_brands {*/
    /*    display: none;*/
    /*}*/
    .every_subcategory_menu {
        grid-gap: 0;
        grid-template-columns: 240px 1fr;
    }

    .subcategory_popup .every_subcategory_menu__subcategory_name .every_subcategory_brands {
        grid-template-columns: 1fr;
    }

    .every_subcategory_menu__category_name,
    .every_subcategory_menu__subcategory_name {
        grid-gap: 16px;
        padding: 40px 20px 20px;
    }

    header:hover .subcategory_popup {
        height: calc(100vh - 336px);
        background: #333;
    }

    header .subcategory_popup {
        height: max-content;
        grid-template-columns: 2fr;
    }
}

@media (max-width: 520px) {

    .every_subcategory_menu {
        grid-template-columns: 1fr;
    }

    .every_subcategory_menu__category_name, .every_subcategory_menu__subcategory_name {
        padding: 50px 20px 20px;
    }

    header:hover .subcategory_popup {
        grid-template-columns: 1fr;
    }

    .every_subcategory_menu a {
        width: fit-content;
    }

    header .subcategory_popup {
        overflow: hidden;
    }

    .every_subcategory_brands {
        padding: 0;
    }

    .every_subcategory_menu__subcategory_name {
        display: none;
    }

    .every_subcategory_menu__category_name .active {
        color: unset
    }


    /*.every_subcategory_menu__subcategory_name {*/
    /*    display: none;*/
    /*}*/
    /*header:hover .subcategory_popup {*/
    /*    height: calc(100vh - 105px);*/
    /*}*/
}

@media (max-width: 480px) {
    /*.subcategory_popup {*/
    /*    overflow-y: auto;*/
    /*    grid-template-columns: 205px 1fr;*/
    /*    !*padding: 10px 0 10px 10px;*!*/
    /*}*/
    /*.subcategory_popup .every_subcategory_brands {*/
    /*    grid-template-columns: repeat(2, 1fr);*/
    /*}*/
    /*.every_subcategory_menu {*/
    /*    grid-template-columns: 1fr;*/
    /*}*/
    .brand_title {
        text-align: center;
        margin-top: 20px;
    }

    .image {
        display: none;
    }

    .image_small {
        display: block;
        width: 30px;
    }

    .header_mobile_close {
        position: absolute;
        cursor: pointer;
        top: 1rem;
        right: 1rem;
    }
}

@media screen and (max-width: 400px) {
    header:hover .subcategory_popup{
        height: calc(100vh - 200px);
    }
}
@media screen and (max-width: 340px) {
    header .navigation .every_navigation_links {
        font-size: 18px;
        line-height: 24px;
    }
    header:hover .subcategory_popup{
        height: calc(100vh - 100px);
    }
}