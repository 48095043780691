.prices_category_wrapper{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding: 8px 0;
    border-top: #EFEFF4 1px solid;
}

.subcategory_wrapper{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    padding: 8px 0;
    border-top: #EFEFF4 1px solid;
}

.prices_properties{
    display: flex;
    margin-left: auto;
    align-items: center;
}

.increment img{
    padding: 0 8px 3px;
}

span{
    font-family: Open Sans;
    font-style: normal;
}

.category_name{
    font-weight: bold;
    font-size: 18px;
    line-height: 41px;
    cursor: pointer;
}

.increment{
    cursor: pointer;
}

.subcategory_name{
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    margin-left: 72px;
}

.category_name img{
    margin: 0 12px 3px;
}

.currency_container{
    border: #D3D3D3 1px solid;
    padding: 4px 12px;
    height: 24px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    cursor: pointer;
    margin-right: 12px;
}

.currency_container span {
    border-right: #D3D3D3 1px solid;
    padding-right: 12px;
}

.currency_container img {
    padding-left: 12px;
}
.sales__wrapper input{
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    border-radius: 2px;
    height: 34px;
    width: 84px;
    margin: 0 12px;
    text-align: center;
}

.sales__wrapper button{
    background: #333333;
    border: 1px solid #333333;
    box-sizing: border-box;
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
}
