.wrapper_filter_by_popular {
    display: grid;
    grid-template-columns: auto 1fr;
}

.wrapper_filter_by_popular .clear_all span {
    cursor: pointer;

}

.wrapper_filter_by_popular .clear_all {
    align-self: center;
    justify-self: end;
    font-size: 14px;
    line-height: 16px;
    text-decoration: underline;
    color: #333
}

.wrapper_filter_by_popular .wrapper_sort_by {
    padding: 30px 0;
}

.wrapper_filter_by_popular .wrapper_sort_by .sort_by_text {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #333;
    align-self: center;
}

.wrapper_favorites_main_page .wrapper_content {
    padding: 30px 0 30px 0;
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-top: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3;
}

.wrapper_favorites_main_page .wrapper_filter_by_popular .wrapper_sort_by {
    display: grid;
    grid-template-columns: max-content 236px;
}

.wrapper_favorites_main_page .wrapper_filter_by_popular .wrapper_sort_by .wrapper_sort_by_block {
    background: #F9F9F9;
    position: relative;
    margin-left: 16px;
}

.wrapper_favorites_main_page .wrapper_filter_by_popular .wrapper_sort_by .wrapper_sort_by_block .sort_by_block {
    padding: 12px 36px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto;
}

.wrapper_favorites_main_page .wrapper_filter_by_popular .wrapper_sort_by .wrapper_sort_by_block .wrapper_drop_down {
    position: absolute;
    background: #F9F9F9;
    margin-top: 4px;
    z-index: 2;
    left: 0;
    right: 0;
}

.wrapper_favorites_main_page .wrapper_filter_by_popular .wrapper_sort_by .wrapper_sort_by_block .wrapper_drop_down .wrapper_every_sort:hover {
    background: #c8c7cc;
    color: #fff;
}

.wrapper_favorites_main_page .wrapper_filter_by_popular .wrapper_sort_by .wrapper_sort_by_block .wrapper_drop_down .wrapper_every_sort {
    border-bottom: 1px solid #c8c7cc;
    cursor: pointer;
    padding: 12px 36px;
    transition: .4s;
}

.wrapper_favorites_main_page .wrapper_filter_by_popular .wrapper_sort_by .wrapper_sort_by_block .active_image{
    transform: rotateX(180deg);
}

.wrapper_favorites_main_page .wrapper_filter_by_popular .wrapper_sort_by .wrapper_sort_by_block img {
    padding-left: 8px;
    align-self: center;
    justify-self: end;
    transition: .4s;
}

.buttons_wrapper {
    display: flex;
    padding: 20px 0 130px 0;
    justify-content: space-between;
}

.buttons_wrapper .wrapper_submit_request a {
    margin-right: 0;
    width: 310px;
}

.buttons_wrapper .wrapper_submit_request .black {
    color: #333333;
    border: 1px solid #333333;
}

.buttons_wrapper .wrapper_submit_request .black:hover {
    background: none;
}

@media (max-width: 768px) {
    .wrapper_favorites_main_page .wrapper_content {
        grid-template-columns: repeat(2, 1fr);
    }

    .buttons_wrapper {
        flex-direction: column;
    }

    .buttons_wrapper .wrapper_submit_request a {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .wrapper_filter_by_popular .clear_all {
        justify-self: start;
    }

    .wrapper_filter_by_popular {
        grid-template-columns: 1fr;
    }

    .buttons_wrapper .wrapper_submit_request .black {
        margin-top: 20px;
    }

    .wrapper_favorites_main_page .wrapper_content {
        grid-template-columns: repeat(1, 1fr);
    }
}
