.wrapper_every_goods {
    padding: 0 4px;
    display: block;
}

.wrapper_every_goods .wrapper_img .add_to_cart_wrapper {
    opacity: 0;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding: 16px 0;
    background: #333;
    transition: .4s;
}

.wrapper_every_goods:hover .wrapper_img .add_to_cart_wrapper {
    opacity: 1;
    bottom: 0;
}

.wrapper_every_goods .wrapper_img {
    position: relative;
    background: #fff;
    height: 200px;
    padding: 4px;
    display: grid;
}

.wrapper_every_goods .wrapper_img .wrapper_img__image_not_found {
    height: 200px;
    display: grid;
    align-content: center;
    border: 1px solid #8e90a6;
    justify-content: center;
}

.wrapper_every_goods .wrapper_img .wrapper_img__image_not_found {
    color: #8e90a6;
}

.wrapper_every_goods .wrapper_img img {
    object-fit: contain;
    height: 100%;
    align-self: center;
    width: 100%;
}

.wrapper_every_goods .wrapper_goods_info {
    padding: 20px 10px;
    word-break: break-word;
}

.wrapper_every_goods .wrapper_goods_info .wrapper_name {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #828282
}

.wrapper_every_goods .wrapper_goods_info .wrapper_brand {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000
}

.wrapper_every_goods .wrapper_goods_info .wrapper_price {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 0 12px;
}

.wrapper_every_goods .wrapper_goods_info .wrapper_price .price {
    font-size: 16px;
    line-height: 22px;
    color: #C69C6D;
}

.wrapper_preview_category_section .preview_category_link_mobile {
    display: none;
}

.wrapper_every_goods .wrapper_goods_info .wrapper_price .old_price {
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: line-through;
    color: #BDBDBD;
}

@media (max-width: 768px) {
    .wrapper_preview_category_section .preview_category_title .preview_category_link {
        display: none;
    }

    .wrapper_preview_category_section .preview_category_link_mobile {
        display: block;
        grid-row: 3;
        grid-column: 1/-1;
    }
    .wrapper_every_goods .wrapper_img img,
    .wrapper_every_goods .wrapper_img {
        height: 200px;
    }

    .wrapper_preview_category_section .preview_category_link{
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 480px) {
    .wrapper_every_goods .wrapper_goods_info {
        padding: 12px 10px;
    }


    .wrapper_every_goods .wrapper_goods_info .wrapper_brand {
        font-size: 16px;
    }
}
