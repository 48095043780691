footer {
    background: #333;
    padding: 80px 90px 40px;
}

footer .wrapper_content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

footer .wrapper_every_block {
    display: grid;
    grid-auto-rows: max-content;
    justify-content: center;
    grid-gap: 20px;
}

footer .wrapper_every_block .block_title {
    font-size: 16px;
    align-self: center;
    line-height: 42px;
    color: #F9F9F9;
}

footer .wrapper_every_block .wrapper_links {
    display: grid;
    grid-gap: 4px;
}

footer .wrapper_every_block .wrapper_links a {
    font-size: 14px;
    line-height: 180%;
    text-decoration: none;
    color: #F9F9F9;
}

footer .wrapper_every_block .logo {
    font-size: 31px;
    line-height: 41px;
    color: #C69C6D;
}

footer .wrapper_info_description {
    display: grid;
    grid-gap: 12px;
}

footer .wrapper_info_description span {
    font-size: 12px;
    line-height: 16px;
    color: #F9F9F9;
}

footer .wrapper_content .wrapper_all_rights_reserved {
    grid-column: 1/-1;
    margin-top: 32px;
    text-align: center;
}

footer .wrapper_content .wrapper_join_to_us_section > span {
    font-size: 16px;
    grid-column: 1/-1;
    line-height: 22px;
    color: #F9F9F9;
}

footer .wrapper_content .mobile_version .wrapper_social_media,
footer .wrapper_content .wrapper_join_to_us_section .wrapper_social_media {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 8px;
    grid-gap: 8px;
}

footer .wrapper_content .wrapper_all_rights_reserved span {
    font-size: 12px;
    line-height: 14px;
    color: #D3D3D3;
}

footer .mobile_version {
    display: none;
}

@media (max-width: 1000px) {
    footer {
        padding: 40px 20px 20px;
    }

    footer .wrapper_content {
        grid-template-columns: repeat(3, 1fr);
    }

    footer .wrapper_content .wrapper_every_block {
        justify-content: start;
    }

    footer .deluxe_mobili_info_wrapper {
        grid-column: 1/-1;
        grid-template-columns: 260px 1fr;
    }

    footer .deluxe_mobili_info_wrapper .wrapper_join_to_us_section {
        justify-self: end;
    }

    footer .mobile_version > span,
    footer .wrapper_join_to_us_section > span {
        line-height: 42px;
    }

    footer .mobile_version img,
    footer .wrapper_join_to_us_section img {
        height: 40px;
        width: 40px;
        margin-top: 18px;
    }

    footer .deluxe_mobili_info_wrapper .wrapper_info_description {
        grid-column: 1;
    }

    footer .deluxe_mobili_info_wrapper .wrapper_join_to_us_section {
        grid-column: 2;
        grid-row: 1/-1;
    }

}

@media (max-width: 768px) {
    footer {
        padding: 40px 28px 20px;
    }

    footer .wrapper_every_block {
        margin-bottom: 12px;
    }

    footer .wrapper_content {
        grid-template-columns: repeat(2, 1fr);
    }

    footer .deluxe_mobili_info_wrapper {
        grid-column: 1/-1;
        grid-template-columns: 350px;
        justify-self: center;
        text-align: center;
    }

    footer .deluxe_mobili_info_wrapper .wrapper_join_to_us_section {
        display: none;
    }

    footer .mobile_version {
        display: block;
    }

    footer .wrapper_join_to_us_section img {
        margin-top: 0;
    }

    footer .wrapper_every_block .block_title {
        font-size: 14px;
        line-height: 140%;
    }
}

@media (max-width: 480px) {
    footer .wrapper_content {
        grid-template-columns: 1fr;
    }

    footer .wrapper_every_block .block_title {
        font-size: 16px;
    }

    footer .mobile_version {
        display: none;
    }

    footer .deluxe_mobili_info_wrapper {
        grid-template-columns: 1fr;
    }

    footer .deluxe_mobili_info_wrapper .wrapper_join_to_us_section {
        display: grid;
        grid-column: 1;
        grid-row: 2;
        justify-self: center;
    }

    footer .wrapper_every_block {
        justify-self: center;
        text-align: center;
    }

}
