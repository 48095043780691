.wrapper_check_box {
    margin-top: 20px;
}

.wrapper_check_box label {
    display: grid;
    grid-template-columns: 18px 1fr;
    grid-gap: 0 8px;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    position: relative;
    cursor: pointer;
}

.wrapper_check_box input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
}

.wrapper_check_box label > span {
    width: 18px;
    position: relative;
    height: 18px;
    border: 1px solid #333;
    box-sizing: border-box;
    margin-right: 12px;
    border-radius: 3px;
    transition: all .4s;
}

.wrapper_check_box .checkbox_active > span {
    animation: bounce 250ms;
}

.wrapper_check_box .checkbox_active > span::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 3px;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0 100%;
    animation: checked-box 125ms 250ms forwards;
}

@keyframes checked-box {
    0% {
        width: 0;
        height: 0;
        border-color: transparent;
        transform: translate(0, 0) rotate(45deg);
    }
    33% {
        width: 4px;
        height: 0;
        transform: translate(0, 0) rotate(45deg);
    }
    100% {
        width: 4px;
        height: 8px;
        border-color: #C69C6D;
        transform: translate(0px, -7px) rotate(45deg);
    }
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    33% {
        transform: scale(.7);
    }
    100% {
        transform: scale(1);
    }
}
