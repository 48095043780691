@import "variables";

.g-sheets {
  margin-left: 255px;
  background-color: #f5f5f5;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }
}

.requests {
  overflow: auto;
  min-height: 125vh;

  & .g-add {
    align-self: center;
    margin: 25px;
  }

  .g-sheets {
    &-table {
      display: block;
      overflow: auto;
    }
  }
}
