.wrapper_user_profile {
    display: grid;
    grid-template-columns: 300px 1fr;
    align-items: start;
    grid-gap: 20px;
}

.wrapper_user_profile_data-email {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: start;
    max-width: 640px;
}

.wrapper_user_profile_data-password {
    grid-template-columns: max-content;
    margin-bottom: 3rem;
}

.wrapper_user_profile_data h3 {
    font-family: 'Playfair Display', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333333;
}

.wrapper_user_profile_data span, .wrapper_user_profile_address span {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 6px;
    line-height: 19px;
    text-transform: uppercase;
    color: #828282;
}

.wrapper_user_profile_address h3 {
    font-family: 'Playfair Display', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333333;
    margin: 0 0 1.5rem;
}

.wrapper_user_profile_data-line {
    height: 1px;
    background: #D3D3D3;
    width: 100%;
    margin: 40px 0 26px;
}

.wrapper_user_profile_data_birth_date {
    display: grid;
    grid-gap: 20px;
    align-items: start;
    grid-template-columns: repeat(3, max-content);
    margin-bottom: 1.5rem;
}

.wrapper_user_profile_data {
    padding-bottom: 3rem;
}

.wrapper_user_profile_data_user_name, .wrapper_user_profile_data_user_phone {
    display: grid;
    align-items: start;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 1.5rem;
}

.wrapper_user_profile_data_user_data {
    max-width: 640px;
}

.wrapper_user_profile_data_user_options p {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
}

.wrapper_user_profile_data_user_options {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    cursor: pointer;
    margin-bottom: 12px;
}

.wrapper_user_profile_data_user_options:last-of-type {
    margin-bottom: 0;
}

.wrapper_user_profile_data_user_options_button {
    border-radius: 100%;
    background: #D3D3D3;
    width: 17px;
    height: 17px;
}

.wrapper_user_profile_data_user_options_button {
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid #D3D3D3;
    width: 16px;
    height: 16px;
    display: grid;
    align-items: center;
    justify-content: center;
}

.wrapper_user_profile_data_user_options_button div {
    border-radius: 100%;
    background: #ffffff;
    width: 8px;
    height: 8px;
    transition: .4s all;
}

.wrapper_user_profile_data_user_options_button_active div {
    background: #333333;
}

.wrapper_user_profile_address-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 26px 20px;
}

.wrapper_user_profile_address-data > .wrapper_user_profile_address-data_street {
    grid-column: 1/-1;
}


.wrapper_user_profile_address {
    max-width: 640px;
}

@media (max-width: 600px) {
    .wrapper_user_profile_address form{
        grid-template-columns: 1fr;
        padding: 20px 0;
    }

    .wrapper_user_profile_data_birth_date {
        grid-template-columns: 1fr 2fr 1fr
    }

    .wrapper_user_profile_data_user_name, .wrapper_user_profile_data_user_phone {
        grid-template-columns: 1fr;
    }

    .wrapper_user_profile_data-password {
        grid-template-columns: 1fr;
    }
}