.banner-image {
    background-image: url("../../assets/about-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 350px;
    position: relative;
    margin-bottom: 20vh;
}

.about-banner-info {
    background: #333333;
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 44vw;
}

.about-banner-info h2{
    font-family: 'Playfair Display', sans-serif;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin: 2.75rem 0 1rem;
}
.about-banner-info p{
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    padding: 0 8vw;
    margin-bottom: 3rem;
}

.wrapper_preview_image img {
    max-width: 100%;
}

.about-info {
    padding: 0;
    margin: 0 0 15.5vh 0;
    background: #FFFFFF;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
}

.about-info .wrapper_preview_info {
    padding: 0;
}

.about-info-reverse .wrapper_preview_info {
    padding-left: 1.75rem;
}

.about_company_page .wrapper_preview_info .wrapper_title {
    font-size: 36px;
    line-height: 48px;
    color: #333333;
}

.about_company_page .wrapper_preview_info .wrapper_desc {
    font-size: 16px;
    line-height: 22px;
 }

.about_company_page .preview_category_link a {
    padding: 0 0 10px 0;
}

.about-info .link {
    border: none;
    border-bottom: 1px solid #C69C6D;
    font-weight: 600;
}

.about-info-reverse .wrapper_preview_image {
    grid-column: 1;
    grid-row: 1;
}

.about-info-reverse .wrapper_preview_info {
    grid-column: 2;
}

.about-map {
    padding: 0;
    margin: 0 0 13.5vh 0;
    background: #FFFFFF;
    grid-template-columns: 5fr 7fr;
    grid-gap: 1rem;
}

.about-map .wrapper_preview_info {
    padding: 0;
}

.about-map .link {
    border: none;
    border-bottom: 1px solid #C69C6D;
    font-weight: 600;
}

.about-services h3{
    font-family: 'Playfair Display', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #333333;
    margin: 0 0 20px 0;
}

.about-services p{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 9vh 0;
    color: #333333;
}

.services-range {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 12vh;
}

.services-range-item {
    width: 21%;
    margin-bottom: 3.75rem;
}

.services-range img{
     margin-bottom: 2rem;
 }

.services-range p{
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    padding-right: 2rem;
    margin: 0;
}

@media screen and (max-width: 480px) {
    .wrapper_app {
        padding: 0 12px;
    }

    .wrapper_preview_section .wrapper_preview_info {
        grid-gap: 5px;
    }

    .about-banner-info {
        width: 70vw;
    }

    .about-info {
        grid-template-columns: 1fr;
    }

    .about-info-reverse .wrapper_preview_info {
        grid-column: 1;
        padding-left: 0;
    }

    .about-map {
        grid-template-columns: 1fr;
    }

    .about_company_page .wrapper_preview_info .wrapper_title {
        font-size: 32px;
        line-height: 40px;
    }

    .about-services {
        text-align: center;
    }

    .services-range-item {
        width: 50%;
    }

    .services-range p {
        padding: 0 10px;
    }
}