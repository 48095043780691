@import "variables";

.categories {
    margin-left: 255px;
    background-color: $color-white-light;

    &__title {
        padding: 32px 24px;
    }

    &-info {
        margin-bottom: 16px;
        border-radius: 5px;
        background-color: $color-white;

        & .g-select {
            max-width: 500px;
            cursor: pointer;

            &-header {
                display: flex;

                &__arrow {
                    transition: $transition;
                }
            }

            &__open {
                .g-select-header__arrow {
                    transform: rotate(180deg);
                }
            }

            &-list {
                transform: translateY(-30px);
            }
        }

        &__head {
            padding: 12px 16px;
            display: grid;
            grid-template-columns: 363px 1fr;
            grid-template-rows: 1fr;
            background-color: $color-white-dark;
        }

        &__text {
            margin: 0;
            font-family: $font;
            font-weight: 600;
            font-size: 14px;
            line-height: 25px;
            color: $color-gray-light-dark;
        }

        &-list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            &__item {
                display: grid;
                align-items: center;
                grid-template-columns: 363px 1fr repeat(3, 44px);
                grid-template-rows: 1fr;
                padding: 4px 16px;
                border-bottom: 1px solid $color-white-gray;
                cursor: default;
            }

            &__item_new{
                display: grid;
                align-items: center;
                grid-template-columns: 1fr repeat(2, 36px);
                grid-template-rows: 1fr;
                padding: 4px 16px;
                border-bottom: 1px solid $color-white-gray;
                cursor: default;
            }

            &__item-no-photo {
                display: grid;
                align-items: center;
                grid-template-columns: 1fr repeat(2, 36px);
                grid-template-rows: 1fr;
                padding: 4px 16px;
                border-bottom: 1px solid $color-white-gray;
                cursor: default;
            }

            &__text {
                margin: 0;
                font-family: $font;
                color: black;
            }

            &__link {
                font-family: $font;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: $color-black;
            }

            &__image {
                width: 44px;
                height: 44px;
                object-fit: cover;
            }
        }
    }

    &-add {
        border-radius: 5px;
        background-color: $color-white;
        padding: 24px 24px 35px;

        &__title {
            margin-bottom: 16px;
            font-family: $font;
            font-weight: bold;
            font-size: 24px;
            line-height: 41px;
            color: $color-black-light;
        }

        &__form {
            display: flex;
        }

        &__block {
            &:first-of-type {
                margin-right: 28px;
            }

            &:last-of-type {
                width: 100%;
            }
        }

        &__label {
            position: relative;
            width: 300px;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background-color: $color-white-gray;
            cursor: pointer;
        }

        &__image {
            top: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
        }

        &__file {
            display: none;
        }

        &__file-new {
            opacity: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        &__text {
            margin: 0;
            margin-bottom: 10px;
            font-family: $font;
            font-weight: 600;
            font-size: 14px;
            line-height: 25px;
            color: $color-gray-dark;
        }

        & .g-input {
            width: 100%;
            margin-bottom: 16px;
        }

        &_flex {
            display: flex;
            justify-content: flex-end;
        }

        & .g-add_margin {
            margin-right: 16px;
        }
    }
}
