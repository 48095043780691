$color-white: #ffffff;
$color-gray: #cecece;
$color-black: #161616;
$color-orange: #fc8517;

$color-white-light: #f5f5f5;
$color-white-gray: #efeff4;
$color-white-gray-dark: #c8c7cc;
$color-white-dark: #f9f9f9;

$color-black-light: #232323;
$color-black-dark: #060606;

$color-gray-light: #d9d9d9;
$color-gray-light-second: #b1b1b1;
$color-gray-light-dark: #6b6c7e;
$color-gray-dark: #828181;
$color-gray-dark-light: #666666;

$color-blue-light: #8d99ae;

$color-pink-light: #fbefef;

$font: 'Open Sans', sans-serif;

$transition: 400ms;