.filter {
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    padding: 20px 30px;
    display: grid;
    grid-gap: 20px 0;
}

.filter .wrapper_every_category {
    display: grid;
    grid-gap: 12px;
}

.filter .wrapper_every_category .every_category_header {
    display: grid;
    cursor: pointer;
    grid-template-columns: 1fr auto;
    grid-gap: 0 12px;
}

.filter .wrapper_every_category .every_category_header span {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #333
}

.filter .wrapper_every_category .every_category_header img {
    justify-self: end;
    align-self: center;
    transition: .4s;
}

.filter .wrapper_every_category .every_category_header .active-image {
    transform: rotateX(180deg);
}

.filter .wrapper_every_category .wrapper_every_pre_category {
    grid-column: 1/-1;
}

.filter .wrapper_every_category .wrapper_every_pre_category .every_pre_category {
    padding-left: 28px;
}

.filter .wrapper_every_category .wrapper_every_pre_category .wrapper_every_search_input {
    margin-top: 12px;
}

.filter .wrapper_every_category .wrapper_every_pre_category .wrapper_every_search_input input {
    padding: 4px 0;
}

.filter .wrapper_every_category .wrapper_every_pre_category .wrapper_pre_category_array {
    max-height: 200px;
    overflow: auto;
    margin: 8px 0;
}

.filter .wrapper_every_category .wrapper_every_pre_category .category_tags {
    font-size: 14px;
    transition: .4s;
    line-height: 30px;
    cursor: pointer;
    color: #333
}

.wrapper_every_pre_category__name {
    display: grid;
}

.wrapper_every_pre_category__name .pl12 {
    padding-left: 12px;
}


.wrapper_every_pre_category__name_text,
.wrapper_every_pre_category__name_link {
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    text-decoration-line: underline;
    width: fit-content;
    color: #C69C6D;
}

.wrapper_every_pre_category__name_text {
    color: #333;
    text-decoration-line: unset;
    cursor: default;
}

.wrapper_every_pre_category__name .pl24 {
    padding-left: 24px;
}

.filter .wrapper_every_category .wrapper_every_pre_category .category_tags:hover,
.filter .wrapper_every_category .wrapper_every_pre_category .category_tags_active {
    color: #C69C6D;
}

.filter .wrapper_reset_filters {
    text-align: center;
    text-decoration: underline;
    font-size: 16px;
    line-height: 25px;
    color: #C69C6D;
}

.filter .wrapper_reset_filters span {
    cursor: pointer;
}

.filter .wrapper_active_filters {
    display: grid;
    grid-gap: 12px;
}

.filter .wrapper_active_filters .every_active_filter {
    padding: 8px 20px;
    background-color: #C69C6D;
    display: grid;
    grid-template-columns: 1fr auto;
}

.filter .wrapper_active_filters .every_active_filter span {
    font-size: 16px;
    line-height: 25px;
    color: #fff
}

.filter .wrapper_active_filters .every_active_filter img {
    justify-self: center;
    cursor: pointer;
    align-self: center;
}

/*multiply range*/

.css-1wwr2lr-histogramContainer {
    display: none !important;
}

.css-i35pig-Slider .rheostat-progress {
    background: #C69C6D !important;
}

.css-i35pig-Slider .rheostat-handle {
    border: 2px solid #C69C6D !important;
    box-sizing: border-box !important;
    width: 16px !important;
    height: 16px !important;
}

.css-i35pig-Slider .rheostat-horizontal .rheostat-handle {
    top: -7px !important;
}

.css-a9jdxq-Container {
    display: grid !important;
    grid-gap: 12px;
}

.css-eo3c1x-Input-input {
    height: 26px !important;
}

/*multiply range*/
.css-a9jdxq-Container > div:first-child {
    grid-row: 2;
    height: 43px;
}

.delete_filter_lap {
    display: none;
}

.filter .wrapper_price_title {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #333;
    margin-bottom: 12px;
}

.range_input input {
    text-align: center;
}

.range_input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.range_input input[type=number] {
    -moz-appearance: textfield;
}

@media (max-width: 1000px) {
    .filter {
        padding: 20px;
    }

    .css-eo3c1x-Input-input {
        padding: 0 0 0 4px;
        font-size: 0.8rem;
    }

}

@media (max-width: 768px) {
    .delete_filter_lap {
        display: block;
    }

    .css-eo3c1x-Input-input {
        padding: 4px 12px;
        font-size: 1rem;
    }

    .filter {
        grid-row: 2;
        grid-column: 1/-1;
        grid-column-end: none;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_goods_length {
        display: none;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content {
        grid-template-columns: 1fr;
        width: 140px;
        grid-row: 1;
        grid-column: 2;
        justify-self: end;
    }
}

@media (max-width: 550px) {
    .filter .wrapper_active_filters .every_active_filter span {
        font-size: 14px;
    }


}

@media (max-width: 400px) {
    .css-i35pig-Slider .rheostat-handle {
        width: 8px !important;
        height: 24px !important;
        margin-top: -4px;
    }
}
