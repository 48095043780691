.custom_date_select_wrapper {
    position: relative;
}

.custom_date_select_wrapper_label {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 1rem;
    align-items: center;
    background: #F9F9F9;
    padding: 12px 14px;
    width: 150px;
    width: -moz-max-content;
    cursor: pointer;
    justify-content: center;
}

.custom_date_select_wrapper_label span {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
    margin: 0;
}

.custom_date_select_wrapper_options {
    position: absolute;
    top: 3.25rem;
    left: 0;
    max-height: 200px;
    z-index: 100;
    overflow-y: scroll;
    width: 150px;
    background: #ffffff;
    box-shadow: 0 3px 5px rgba(9, 30, 66, 0.2), 0 0 1px rgba(9, 30, 66, 0.31);
}

.custom_date_select_wrapper_options span {
    width: 100%;
    padding: 8px 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #4E5D78;
    margin: 0;
}

.custom_date_select_wrapper_options span:hover {
    background: #EBEBF0;
    cursor: pointer;
}

.custom_date_select_wrapper_error {
    border: 1px solid #000;
    background: #28293d;
    border-radius: 4px;
    padding: 5px 8px;
    position: absolute;
    left: -4rem;
    margin-top: 1rem;
    z-index: 10;
    width: -moz-max-content;
    width: max-content;
}

.custom_date_select_wrapper_error span {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    margin: 0;
    text-transform: none;
}

.custom_date_select_wrapper_error span:before {
    content: "";
    width: 10px;
    height: 10px;
    background: #28293d;
    position: absolute;
    left: 50%;
    top: -3px;
    transform: rotate(42deg) translateX(-50%);
}

@media (max-width: 600px) {
    .custom_date_select_wrapper_label {
        width: 100%;
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
    }

    .custom_date_select_wrapper_label img {
        display: none;
    }

    .custom_date_select_wrapper_options {
        top: 3.25rem;
        width: 100%;
    }

    .custom_date_select_wrapper_error {
        left: -15rem;
    }
}


@media (max-width: 325px) {
    /* todo: */
    /*.custom_date_select_wrapper_error {*/
    /*    left: -13rem;*/
    /*}*/
}