.main_page_conditions {
    padding-left: 20px;
}


.title {
    font-family: 'Playfair Display', sans-serif;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #333333;
    margin: 0;
}

.subtitle {
    font-family: 'Playfair Display', sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    color: #333333;
    margin-top: 0;
}

.content {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    margin-top: 0;
}

.content_other {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.02em;
    color: #333333;
    margin-bottom: 0;
}


.main_page_conditions a {
    color: #333333;
    text-decoration: underline;
    text-decoration-color: #333;
}

@media (max-width: 850px) {
    .wrapper_user_profile {
        display: block;
    }

    .wrapper_navigation_navbar {
        min-height: 0;
    }

    .title {
        padding: 33px 0 15px 0;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 22px;
        line-height: 25px;
        padding: 33px 0 15px 0;
    }

    .subtitle {
        font-size: 17px;
        line-height: 25px;
        padding-bottom: 15px;
    }

    .wrapper_navigation_navbar .playfair_display_400 {
        font-size: 15px;
    }
}