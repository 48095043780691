input {
    border-color: #333;
    border-width: 0 0 1px 0;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    width: 100%;
    font-size: 14px;
    background: transparent;
    line-height: 19px;
    color: #333;
    padding: 8px 12px;
}

.input_dark_theme {
    border-color: #4f4f4f;
    border-width: 0 0 1px 0;
    color: #fff
}

.input_promo_code {
    font-size: 14px;
    padding: 16px 12px;
    width: auto;
    line-height: 19px;
    color: #fff;
    background: #272727;
}
