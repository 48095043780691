.preview_catalog_category {
    height: 300px;
    position: relative;
    margin-top: -14px;
}

.preview_catalog_category img {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    z-index: 0;
    top: 0;
    object-fit: cover;
}

.preview_catalog_category .wrapper_info_catalog_preview {
    z-index: 1;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 12px 0;
    padding: 54px 60px;
    position: relative;
}

.preview_catalog_category .wrapper_info_catalog_preview .title_text {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 0.02em;
    color: #fff
}

.preview_catalog_category .wrapper_info_catalog_preview .desc_text {
    font-size: 16px;
    max-width: 75%;
    line-height: 22px;
    color: #fff
}

.wrapper_catalog_main_content {
    display: grid;
    grid-template-columns: 309px 1fr;
    margin-bottom: 60px;
    grid-gap: 20px;
}

.wrapper_catalog_main_content .wrapper_content {
    align-self: start;
    display: grid;
    grid-gap: 30px 0;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column: 1/-1;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by {
    padding: 28px 0;
    display: grid;
    grid-template-columns: max-content 236px;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by > span {
    align-self: center;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block .wrapper_drop_down {
    position: absolute;
    background: #F9F9F9;
    margin-top: 4px;
    z-index: 2;
    left: 0;
    right: 0;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block .wrapper_drop_down .wrapper_every_sort {
    border-bottom: 1px solid #c8c7cc;
    cursor: pointer;
    padding: 12px 36px;
    transition: .4s;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block .wrapper_drop_down .wrapper_every_sort:hover {
    background: #c8c7cc;
    color: #fff;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block .wrapper_drop_down .wrapper_every_sort:last-child {
    border-bottom: none;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block {
    background: #F9F9F9;
    position: relative;
    margin-left: 16px;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block > .sort_by_block span {
    font-size: 16px;
    line-height: 19px;
    color: #333
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block > .sort_by_block {
    padding: 12px 36px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block img {
    padding-left: 8px;
    align-self: center;
    justify-self: end;
    transition: .4s;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block .active_image {
    transform: rotateX(180deg);
}

.wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_goods_length {
    justify-self: end;
    align-self: center;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #333
}

.mobili_version {
    display: none;
}

.wrapper_catalog_main_content .wrapper_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.wrapper_brands_slider {
    grid-column: 1/-1;
    margin: 0;
    width: 100%;
    padding: 0 36px;
}


.wrapper_filter_mobile_show {
    display: none;
}

.wrapper_catalog_find_items {
    display: grid;
    grid-gap: 24px 0;
    grid-column: 1/-1;
    justify-content: center;
}

.wrapper_catalog_find_items img {
    justify-self: center;
    align-self: center;
}

.wrapper_catalog_find_items span {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #000
}

.brands_slider_catalogue_page {
    grid-column: -1/1;
    width: 95%;
    justify-self: center;
}

.brands_text {
    font-size: 20px;
    justify-self: center;
    text-align: center;
    color: #C4C4C4;
    word-break: break-word;
}

.wrapper_every_brand {
    padding: 0;
    display: grid !important;
    align-content: center;
    height: 64px;
}

.wrapper_brands_slider .slick-prev {
    left: -33px;
}

.wrapper_brands_slider .slick-next {
    right: -33px;
}

.mobile_brand_slider {
    display: none;
}

.wrapper_catalog_main_content .wrapper_content .wrapper_catalogue_items {
    display: grid;
    grid-column: 1/-1;
    grid-gap: 8px;
    grid-template-columns: repeat(4, minmax(150px, 1fr));
}

@media (min-width: 1800px) {
    .wrapper_every_brand {
        padding: 0;
    }
}

@media (max-width: 1500px) {
    .wrapper_catalog_main_content .wrapper_content .wrapper_catalogue_items {
        grid-template-columns: repeat(3, minmax(150px, 1fr));
    }
}

@media (max-width: 1000px) {
    .wrapper_catalog_main_content .wrapper_content .wrapper_catalogue_items {
        grid-template-columns: repeat(4, minmax(150px, 1fr));
    }

    .wrapper_catalog_main_content {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .preview_catalog_category {
        margin-top: 0;
        height: auto;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_catalogue_items {
        grid-template-columns: repeat(3, minmax(150px, 1fr));
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_brands_slider {
        padding: 0;
    }

    .wrapper_catalog_main_content .wrapper_pagination {
        grid-row: 4;
    }

    .preview_catalog_category .wrapper_info_catalog_preview {
        padding: 40px 20px;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block .wrapper_drop_down .wrapper_every_sort {
        padding: 8px 20px;
    }

    .mobili_version .wrapper_show_filter {
        width: max-content;
        background-color: #F9F9F9;
        padding: 12px 20px;
        cursor: pointer;
    }

    .mobili_version .wrapper_show_filter img {
        padding-left: 12px;
    }

    .wrapper_filter_mobile_show,
    .mobili_version {
        display: grid;
        align-content: center;
    }


    .wrapper_catalog_main_content {
        grid-template-columns: 1fr;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_sort_by {
        height: max-content;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block {
        background-color: #fff;
        border: 1px solid #D3D3D3;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block > .sort_by_block {
        padding: 4px 12px;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content {
        width: 292px;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by {
        grid-template-columns: max-content 1fr;
    }

    .brands_text {
        font-size: 18px;
    }

    .preview_catalog_category .wrapper_info_catalog_preview .desc_text {
        max-width: 100%;
    }
}

@media (max-width: 550px) {
    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by > span {
        display: none;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_catalogue_items {
        display: grid;
        grid-column: 1/-1;
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_catalogue_items .wrapper_every_goods .wrapper_img img {
        object-fit: contain;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by {
        grid-template-columns: max-content 185px;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block {
        margin: 0
    }

    .wrapper_every_goods .wrapper_img img {
        object-fit: cover;
    }

    .wrapper_every_brand {
        padding: 0;
        height: 80px;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by {
        grid-template-columns: 1fr;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content .wrapper_sort_by .wrapper_sort_by_block > .sort_by_block span {
        font-size: 14px;
    }

    .wrapper_catalog_main_content .wrapper_content .wrapper_header_content {
        width: 128px !important;
    }

    /*.wrapper_catalog_main_content .wrapper_content  {*/
    /*    grid-template-columns: 1fr;*/
    /*}*/
}

@media (max-width: 420px) {
    .wrapper_catalog_main_content .wrapper_content .wrapper_catalogue_items {
        grid-template-columns: 1fr;
    }
}